import React, { useState } from "react";
import MainBanner from "../../Components/Banner/MainBanner";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import "../../assets/css/Contact.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import StripeForm from "../../Components/PaymentMethod/StripeForm";
import { PostCampaignDonation } from "../../network/Network";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

function Donate() {
	const Token = useSelector((state) => state.AuthReducer.token);
	const location = useLocation()
	console.log(location)
	const [firstName, setFirstName] = useState("")
	const [lastName, setLastName] = useState("")
	const [phone, setPhone] = useState("")
	const [email, setEmail] = useState("")
	const [address, setAddress] = useState("")
	const [city, setCity] = useState("")
	const [userSate, setUserState] = useState("")
	const [zip, setZip] = useState("")
	const [country, setCountry] = useState("")
	const [amount, setAmount] = useState("")
	const [stripeToken, setStripeToken] = useState("")
	const [is_anonymous, setIs_anonymous] = useState(false)
	const [loading, setLoading] = useState(false)

	const styling = {
		marginTop: "10px",
		width: "20%",
		padding: "8px 16px",
		borderRadius: "20px",
	};

	
	const SubmitDonation = (e,stripe_token) => {
		e.preventDefault();
		setLoading(true);
		if (
		  !firstName ||
		  !lastName ||
		  !phone || !country || !amount ||
		  !email || !address || !city || !userSate || !zip
		) {
		  setLoading(false);
		  toast.error("Please Enter All Field");
		  return;
		}
		let data = new FormData();
		data.append("campaign_id", location?.state?.data);
		data.append("donation_type", "campaign");
		data.append("f_name", firstName);
		data.append("l_name", lastName);
		data.append("email", email);
		data.append("phone", phone);
		data.append("address", address);
		data.append("city", city);
		data.append("state", userSate);
		data.append("post_code", zip);
		data.append("country", country);
		data.append("is_anonymous", is_anonymous ? 1 : 0);
		data.append("amount", amount);
		data.append("_token", stripe_token);
		PostCampaignDonation(data, Token)
		  .then((res) => {
			console.log(res);
			toast.success(res?.data?.message);
			setLoading(false);
			setFirstName("")
			setLastName("")
			setEmail("")
			setPhone("")
			setAddress("")
			setCity("")
			setUserState("")
			setZip("")
			setCountry("")
			setIs_anonymous(false)
			setAmount("")
		  })
		  .catch((err) => {
			setLoading(false);
		  });
	  }
	return (
		<>
			<Header />
			<MainBanner name={"Campaign"} />
			<section className="contact-page">
				<div className="container">
					<div>
						<h2 className="main-Title">Donate Now</h2>
					</div>
					<div className="border-bottom22"></div>
					<div className="input-div mt-5">
						<div className="row amount_section">
							<h3>Choose Your Amount</h3>
							<div className="col-md-4">
								<label
									htmlFor="abcd"
									className="single_amount w-100 text-center"
								>
									<input type="radio" id="abcd" name="rad" hidden />
									<span>$ 50.00</span>
								</label>
							</div>
							<div className="col-md-4">
								<label
									htmlFor="abcd1"
									className="single_amount w-100 text-center"
								>
									<input type="radio" id="abcd1" name="rad" hidden />
									<span>$ 100.00</span>
								</label>
							</div>
							<div className="col-md-4">
								<label
									htmlFor="abcd2"
									className="single_amount w-100 text-center"
								>
									<input type="radio" id="abcd2" name="rad" hidden />
									<span>$ 150.00</span>
								</label>
							</div>
							<div className="col-md-12 mt-4">
								<label>Amount*</label>
								<input type="number" className="w-100" 
									value={amount}
									onChange={(e)=>setAmount(e.target.value)}
								/>
							</div>
						</div>
					</div>
					<div className="row mt-5 amount_section2">
						<h3 className="details_head">Details</h3>
						<div className="col-lg-6">
							<div className="input-div">
								<label>First Name*</label>
								<input type="text" 
									value={firstName}
									onChange={(e)=>setFirstName(e.target.value)}
								/>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="input-div">
								<label>Last name*</label>
								<input type="text" 
									value={lastName}
									onChange={(e)=>setLastName(e.target.value)}
								/>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="input-div">
								<label>Email*</label>
								<input type="text" 
									value={email}
									onChange={(e)=>setEmail(e.target.value)}
								/>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="input-div">
								<label>Phone*</label>
								<input type="number"
									value={phone}
									onChange={(e)=>setPhone(e.target.value)}
								/>
							</div>
						</div>
						<div className="col-lg-12">
							<div className="input-div">
								<label>Address*</label>
								<input type="text" 
									value={address}
									onChange={(e)=>setAddress(e.target.value)}
								/>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="input-div">
								<label>City*</label>
								<input type="text" 
									value={city}
									onChange={(e)=>setCity(e.target.value)}
								/>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="input-div">
								<label>State*</label>
								<input type="text"
									value={userSate}
									onChange={(e)=>setUserState(e.target.value)}
								/>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="input-div">
								<label>Post Code*</label>
								<input type="number" 
									value={zip}
									onChange={(e)=>setZip(e.target.value)}
								/>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="input-div">
								<label>Country*</label>
								<input type="text" 
									value={country}
									onChange={(e)=>setCountry(e.target.value)}
								/>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="input-div">
									<div className="row">
										{/* <div className="col-md-2 centerRadioBtn"></div> */}
										<div className="col-md-12">
											{/* <p className="sub-title-sm ">Gender</p> */}
											<label
												htmlFor="gender"
												className="for_radio sub-title-sm"
											>
												<input  type="checkbox" 
													 value={is_anonymous}
													 onChange={(e) => setIs_anonymous(!is_anonymous)}
												/>
												<span>Is Anonymous</span>
											</label>
										</div>
									</div>
									</div>
								</div>
						
						<div className="col-lg-12">
							{/* <div className="btn-container mt-4 mb-5">
								<button className="btn book-btn2">Donate Now</button>
							</div> */}
							<div className="input-div mt-3 amount_section3">
								<h3 className="details_head2">Payment Details </h3>
								<StripeForm 
									styling={styling} 
									paynow="Donate Now" 
									PaymentHandler={SubmitDonation}
									loading={loading}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
}

export default Donate;
