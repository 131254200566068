import React, { useState } from "react";
import { useLocation } from "react-router-dom";
// import { Rating } from "react-simple-star-rating";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBedPulse, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
// import Heart from "react-heart";
import ProductImagesSlider from "../../Components/Slider/ProductImagesSlider";
import AllReviews from "../../Components/Reviews/AllReviews";
import ReviewBox from "../../Components/Reviews/ReviewBox";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import { map, plant1, v1 } from "../../constant";
import ProductCard from "../../Components/Cards/ProductCard";
import ActionButton from "../../Components/ActionBtn/ActionButton";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import FrequentlyCard from "./FrequentlyCard";
import MainBanner from "../../Components/Banner/MainBanner";
import NutrientsProSlider from "../../Components/Slider/NutrientsProSlider";
import "../../assets/css/ProductDetails.css";
import { style } from "@mui/system";
import {
  GetProductDetailsApi,
  GetProductRatingApi,
  PostAddWishList,
  PostProductReviewApi,
} from "../../network/Network";
import { useEffect } from "react";
import { AddToCart } from "../../redux/actions/CartActions";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

function GeneralHydroponic() {
  const dispatch = useDispatch();
  const cartDataRedux = useSelector((state) => state.CartReducer.cartData);
  const Token = useSelector((state) => state.AuthReducer.token);
  let currentUrl = window.location.href.split("/");
  const [productDetails, setProductDetails] = useState([]);
  const [spinLoad, setSpinLoad] = useState(false);
  const [wishLoading, setWishLoading] = useState(false);

  const location = useLocation();
  console.log(location?.state?.data);
  const ParamData = location?.state?.data;
  console.log(ParamData);

  const [active, setActive] = useState(false);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [reviewLoading, setReviewLoading] = useState(false);

  const [reviewSpin, setReviewSpin] = useState(false);
  const [reviewData, setReviewData] = useState([]);

  const handleRating = (rate) => {
    if (rate == "20") {
      setRating(1);
    } else if (rate == "40") {
      setRating(2);
    } else if (rate == "60") {
      setRating(3);
    } else if (rate == "80") {
      setRating(4);
    } else {
      setRating(5);
    }
    // setRating(rate);
  };

  useEffect(() => {
    setSpinLoad(true);
    GetProductDetailsApi(currentUrl[4])
      .then((res) => {
        console.log("details", res);
        setProductDetails(res?.data);
        setSpinLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setSpinLoad(false);
      });
  }, [currentUrl[4]]);

  const AddWishListHandler = (e) => {
    e.preventDefault();
    setWishLoading(true);
    let data = {
      product_id: currentUrl[4],
    };
    console.log(data);
    PostAddWishList(data, Token)
      .then((res) => {
        setWishLoading(false);
        console.log(res);
        setActive(!active);
        toast.success("Product Add to your Wishlist");
        // dispatch(WishListData(res?.data?.data?.wishlist))
      })
      .catch((err) => {
        setWishLoading(false);
        console.log(err?.response);
        toast.error(err?.response?.data?.message);
      });
  };

  const AddToCartHandler = (item) => {
    let checkItemAlreadyExist = cartDataRedux.filter(
      (val) => val?.id === item?.id
    );
    if (checkItemAlreadyExist.length > 0) {
      alert("Item Already Exist in Cart!!!");
    } else {
      let colorData = JSON.parse(ParamData?.colors);
      let data = {
        id: item?.id,
        //   price: item?.unit_price,
        //   quantity: quantity,
        //   color: color ? color : colorData,
        //   size: sizeData,
        productitem: item,
      };
      dispatch(AddToCart(data));
      // Navigate("/Product-cart");
    }
  };

  const SubmitRating = (e) => {
    e.preventDefault();
    setReviewLoading(true);
    if (!rating || !comment) {
      toast.error("Please Enter All Fields");
      setReviewLoading(false);
      return;
    }
    let data = {
      product_id: productDetails?.id,
      comment: comment,
      rating: rating,
    };
    PostProductReviewApi(data, Token)
      .then((res) => {
        setReviewLoading(false);
        console.log("post review", res);
        // dispatch(ProductAllReviews(res?.data?.data));
        toast.success("Successfully send");
        setRating("");
        setComment("");
      })
      .catch((err) => {
        setReviewLoading(false);
        console.log(err?.response?.data?.message);
        if (err?.response?.data?.message === "Unauthenticated.") {
          toast.error("Please Login");
        }
        setRating("");
        setComment("");
      });
  };

  useEffect(() => {
    setReviewSpin(true);
    GetProductRatingApi(currentUrl[4])
      .then((res) => {
        console.log("reviews", res);
        setReviewData(res?.data?.data);
        setReviewSpin(false);
      })
      .catch((err) => {
        console.log(err);
        setReviewSpin(false);
      });
  }, [currentUrl[4],reviewData]);

  return (
    <>
      <Header />
      <MainBanner name={"General Hydroponics"} />
      <div className="product-details hypdross">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <NutrientsProSlider />
            </div>
            <div
              className="col-lg-6"
              style={{ zIndex: "999", position: "relative" }}
            >
              <div style={{ zIndex: "9999" }}>
                <div className="row">
                  <div className="col-lg-6">
                    <p className="maples">{productDetails?.category?.name}</p>
                    <h3 className="productName  productName2">
                      {productDetails?.name}
                    </h3>
                  </div>
                  <div className="col-lg-6">
                    <div className="tags-right">
                      <p>Unit Price : ${productDetails?.unit_price}</p>
                    </div>
                  </div>
                </div>
                <p className="desc-title">Description</p>
                <p
                  className="productDesc"
                  dangerouslySetInnerHTML={{ __html: productDetails?.details }}
                />

                {/* <hr style={{ color: "#ffff" }} /> */}
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-12 pack-wrap">
                    <p className="desc-title">Choose Your Pack: </p>
                    {productDetails?.variation?.map((item, index) => {
                      return (
                        <div className="packValue" key={index}>
                          <p>
                            {item?.type}
                            <br />
                            <span>${item?.price}</span>
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <p className="desc-title title2">Donation </p>
                <div className="row main-borderAmount">
                  <div className="col-lg-6 col-sm-12 col-12 quantity-wrap">
                    <div className="text2">Amount to donate : </div>
                    <div>
                      <select name="size" id="size" className="sizeSelect">
                        <option value="S">$15</option>
                        <option value="M">SMALL</option>
                        <option value="L">LARGE</option>
                        <option value="XL">XL</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12 col-12 quantity-wrap">
                    <div className="text2">Select Campaign : </div>
                    <div>
                      <select name="size" id="size" className="sizeSelect">
                        <option value="S">Save Nature</option>
                        <option value="M">SMALL</option>
                        <option value="L">LARGE</option>
                        <option value="XL">XL</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="mt-4 mb-4 cardButtonAdjust">
                  <button type="button" className="btn sizeBtn setbuttonprod">
                    Add To Cart &nbsp;
                    <span>
                      <ShoppingCartOutlinedIcon
                        style={{ color: "#296253", fontSize: "18px" }}
                        className="cartIcon"
                      />
                    </span>
                  </button>
                  <button
                    type="button"
                    className="btn sizeBtn"
                    onClick={(e) => AddWishListHandler(e)}
                    disabled={wishLoading}
                  >
                    {wishLoading ? "Loading.." : "Add To Wishlist"} &nbsp;
                    <span>
                      <FavoriteBorderOutlinedIcon
                        className="heartIcon"
                        style={{ color: "#296253", fontSize: "18px" }}
                      />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="bottom-container">
            <section className="ClientsReviewsSec">
              <div className="container">
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="reviews"
                    role="tabpanel"
                    aria-labelledby="reviews-tab"
                  >
                    <div className="row">
                      <div className="col-lg-8">
                        <div className="review-container">
                          <div>
                            <AllReviews 
                              reviewData={reviewData}
                            />
                          </div>
                          <hr />
                          <div>
                            <ReviewBox
                              handleRating={handleRating}
                              rating={rating}
                              SubmitRating={SubmitRating}
                              comment={comment}
                              setComment={setComment}
                              reviewLoading={reviewLoading}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <ActionButton />

      <Footer />
    </>
  );
}

export default GeneralHydroponic;
