import React, { useEffect, useState } from "react";
import "../../assets/css/Faqs.css";
import { Link } from "react-router-dom";
import Header from "../../Components/Header/Header";
import MainBanner from "../../Components/Banner/MainBanner";
import Footer from "../../Components/Footer/Footer";
import SpinLoader from "../../Components/Loader/SpinLoader";
import { GetFaqs } from "../../network/Network";

function Faqs() {
	const [spinLoad, setSpinLoad] = useState(false);
	// const [faqsData, setFaqsData] = useState([])

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		setSpinLoad(true);
		GetFaqs()
		  .then((res) => {
			console.log(res);
			// setFaqsData(res?.data?.response?.data);
			setSpinLoad(false);
		  })
		  .catch((err) => {
			console.log(err);
			setSpinLoad(false);
		  });
	  }, []);

	const faqsData = [
		{
			id: 1,
			question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit?",
			answer:
				"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ac auctor augue mauris fermentum et.",
		},
		{
			id: 2,
			question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit?",
			answer:
				"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ac auctor augue mauris fermentum et.",
		},
		{
			id: 3,
			question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit?",
			answer:
				"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ac auctor augue mauris fermentum et.",
		},
		{
			id: 4,
			question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit?",
			answer:
				"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ac auctor augue mauris fermentum et.",
		},
	];

	return (
		<>
			<Header />
			<MainBanner name={"FAQ's"} />

			{/* <section className="faq">
				<div className="container">
					<div className="row">
						<div className="col-lg-2"></div>
						<div className="col-lg-8">
							<div className="faq-wrapper">
								<div className="faq-heading-wrapper">
									<h2>Questions Answerd</h2>
									<p className="main-para">
										{" "}
										Nisl vel pretium lectus quam id leo. Odio ut enim blandit
										volutpat maecenas volutpat blandit. Nunc vel risus commodo
										viverra maecenas. Lacus luctus accumsan tortor posuere ac ut
										consequat.{" "}
									</p>
								</div>

								<div className="accordion" id="accordionExample">
									<div className="accordion-item">
										<h2 className="accordion-header" id="headingOne">
											<button
												class="accordion-button"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#collapseOne"
												aria-expanded="true"
												aria-controls="collapseOne"
											>
												Lorem ipsum dolor sit amet, consectetur adipiscing elit?
											</button>
										</h2>
										<div
											id="collapseOne"
											class="accordion-collapse collapse show"
											aria-labelledby="headingOne"
											data-bs-parent="#accordionExample"
										>
											<div className="accordion-body">
												Lorem ipsum dolor sit amet, consectetur adipiscing elit,
												sed do eiusmod tempor incididunt ut labore et dolore
												magna aliqua. Ac auctor augue mauris fermentum et.
											</div>
										</div>
									</div>
									<div className="accordion-item">
										<h2 className="accordion-header" id="headingTwo">
											<button
												class="accordion-button collapsed"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#collapseTwo"
												aria-expanded="false"
												aria-controls="collapseTwo"
											>
												Lorem ipsum dolor sit amet, consectetur adipiscing elit?
											</button>
										</h2>
										<div
											id="collapseTwo"
											class="accordion-collapse collapse"
											aria-labelledby="headingTwo"
											data-bs-parent="#accordionExample"
										>
											<div className="accordion-body">
												Lorem ipsum dolor sit amet, consectetur adipiscing elit,
												sed do eiusmod tempor incididunt ut labore et dolore
												magna aliqua. Ac auctor augue mauris fermentum et.
											</div>
										</div>
									</div>
									<div className="accordion-item">
										<h2 className="accordion-header" id="headingThree">
											<button
												class="accordion-button collapsed"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#collapseThree"
												aria-expanded="false"
												aria-controls="collapseThree"
											>
												Lorem ipsum dolor sit amet, consectetur adipiscing elit?
											</button>
										</h2>
										<div
											id="collapseThree"
											class="accordion-collapse collapse"
											aria-labelledby="headingThree"
											data-bs-parent="#accordionExample"
										>
											<div className="accordion-body">
												Lorem ipsum dolor sit amet, consectetur adipiscing elit,
												sed do eiusmod tempor incididunt ut labore et dolore
												magna aliqua. Ac auctor augue mauris fermentum et.
											</div>
										</div>
									</div>
									<div className="accordion-item">
										<h2 className="accordion-header" id="headingFour">
											<button
												class="accordion-button collapsed"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#collapseFour"
												aria-expanded="false"
												aria-controls="collapseFour"
											>
												Lorem ipsum dolor sit amet, consectetur adipiscing elit?
											</button>
										</h2>
										<div
											id="collapseFour"
											class="accordion-collapse collapse"
											aria-labelledby="headingFour"
											data-bs-parent="#accordionExample"
										>
											<div className="accordion-body">
												Lorem ipsum dolor sit amet, consectetur adipiscing elit,
												sed do eiusmod tempor incididunt ut labore et dolore
												magna aliqua. Ac auctor augue mauris fermentum et.
											</div>
										</div>
									</div>
									<div className="accordion-item">
										<h2 className="accordion-header" id="headingFive">
											<button
												class="accordion-button collapsed"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#collapseFive"
												aria-expanded="false"
												aria-controls="collapseFive"
											>
												Lorem ipsum dolor sit amet, consectetur adipiscing elit?
											</button>
										</h2>
										<div
											id="collapseFive"
											class="accordion-collapse collapse"
											aria-labelledby="headingFive"
											data-bs-parent="#accordionExample"
										>
											<div className="accordion-body">
												Lorem ipsum dolor sit amet, consectetur adipiscing elit,
												sed do eiusmod tempor incididunt ut labore et dolore
												magna aliqua. Ac auctor augue mauris fermentum et.
											</div>
										</div>
									</div>
									<div className="accordion-item">
										<h2 className="accordion-header" id="headingSix">
											<button
												class="accordion-button collapsed"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#collapseSix"
												aria-expanded="false"
												aria-controls="collapseSix"
											>
												Lorem ipsum dolor sit amet, consectetur adipiscing elit?
											</button>
										</h2>
										<div
											id="collapseSix"
											class="accordion-collapse collapse"
											aria-labelledby="headingSix"
											data-bs-parent="#accordionExample"
										>
											<div className="accordion-body">
												Lorem ipsum dolor sit amet, consectetur adipiscing elit,
												sed do eiusmod tempor incididunt ut labore et dolore
												magna aliqua. Ac auctor augue mauris fermentum et.
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-2"></div>
					</div>
				</div>
			</section> */}
			<section class="faqs_sec">
				<div class="container">
					<div class="row">
						<div class="col-lg-2"></div>
						<div class="col-lg-8">
							<div class="heading">
								<h2>Questions Answerd</h2>
								<p>
									Nisl vel pretium lectus quam id leo. Odio ut enim blandit
									volutpat maecenas volutpat blandit. Nunc vel risus commodo
									viverra maecenas. Lacus luctus accumsan tortor posuere ac ut
									consequat.
								</p>
							</div>
							<div class="accordion" id="accordionExample">
								<div class="accordion-item">
									<h2 class="accordion-header" id="headingOne">
										<button
											class="accordion-button"
											type="button"
											data-bs-toggle="collapse"
											data-bs-target="#collapseOne"
											aria-expanded="true"
											aria-controls="collapseOne"
										>
											Lorem ipsum dolor sit amet, consectetur adipiscing elit?
										</button>
									</h2>
									<div
										id="collapseOne"
										class="accordion-collapse collapse show"
										aria-labelledby="headingOne"
										data-bs-parent="#accordionExample"
									>
										<div class="accordion-body">
											Lorem ipsum dolor sit amet, consectetur adipiscing elit,
											sed do eiusmod tempor incididunt ut labore et dolore magna
											aliqua. Ac auctor augue mauris fermentum et.
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<h2 class="accordion-header" id="headingTwo">
										<button
											class="accordion-button collapsed"
											type="button"
											data-bs-toggle="collapse"
											data-bs-target="#collapseTwo"
											aria-expanded="false"
											aria-controls="collapseTwo"
										>
											Lorem ipsum dolor sit amet, consectetur adipiscing elit?
										</button>
									</h2>
									<div
										id="collapseTwo"
										class="accordion-collapse collapse"
										aria-labelledby="headingTwo"
										data-bs-parent="#accordionExample"
									>
										<div class="accordion-body">
											Lorem ipsum dolor sit amet, consectetur adipiscing elit,
											sed do eiusmod tempor incididunt ut labore et dolore magna
											aliqua. Ac auctor augue mauris fermentum et.
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<h2 class="accordion-header" id="headingThree">
										<button
											class="accordion-button collapsed"
											type="button"
											data-bs-toggle="collapse"
											data-bs-target="#collapseThree"
											aria-expanded="false"
											aria-controls="collapseThree"
										>
											Lorem ipsum dolor sit amet, consectetur adipiscing elit?
										</button>
									</h2>
									<div
										id="collapseThree"
										class="accordion-collapse collapse"
										aria-labelledby="headingThree"
										data-bs-parent="#accordionExample"
									>
										<div class="accordion-body">
											Lorem ipsum dolor sit amet, consectetur adipiscing elit,
											sed do eiusmod tempor incididunt ut labore et dolore magna
											aliqua. Ac auctor augue mauris fermentum et.
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<h2 class="accordion-header" id="headingFour">
										<button
											class="accordion-button collapsed"
											type="button"
											data-bs-toggle="collapse"
											data-bs-target="#collapseFour"
											aria-expanded="false"
											aria-controls="collapseFour"
										>
											Lorem ipsum dolor sit amet, consectetur adipiscing elit?
										</button>
									</h2>
									<div
										id="collapseFour"
										class="accordion-collapse collapse"
										aria-labelledby="headingFour"
										data-bs-parent="#accordionExample"
									>
										<div class="accordion-body">
											Lorem ipsum dolor sit amet, consectetur adipiscing elit,
											sed do eiusmod tempor incididunt ut labore et dolore magna
											aliqua. Ac auctor augue mauris fermentum et.
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<h2 class="accordion-header" id="headingFive">
										<button
											class="accordion-button collapsed"
											type="button"
											data-bs-toggle="collapse"
											data-bs-target="#collapseFive"
											aria-expanded="false"
											aria-controls="collapseFive"
										>
											Lorem ipsum dolor sit amet, consectetur adipiscing elit?
										</button>
									</h2>
									<div
										id="collapseFive"
										class="accordion-collapse collapse"
										aria-labelledby="headingFive"
										data-bs-parent="#accordionExample"
									>
										<div class="accordion-body">
											Lorem ipsum dolor sit amet, consectetur adipiscing elit,
											sed do eiusmod tempor incididunt ut labore et dolore magna
											aliqua. Ac auctor augue mauris fermentum et.
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<h2 class="accordion-header" id="headingSix">
										<button
											class="accordion-button collapsed"
											type="button"
											data-bs-toggle="collapse"
											data-bs-target="#collapseSix"
											aria-expanded="false"
											aria-controls="collapseSix"
										>
											Lorem ipsum dolor sit amet, consectetur adipiscing elit?
										</button>
									</h2>
									<div
										id="collapseSix"
										class="accordion-collapse collapse"
										aria-labelledby="headingSix"
										data-bs-parent="#accordionExample"
									>
										<div class="accordion-body">
											Lorem ipsum dolor sit amet, consectetur adipiscing elit,
											sed do eiusmod tempor incididunt ut labore et dolore magna
											aliqua. Ac auctor augue mauris fermentum et.
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-2"></div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
}

export default Faqs;

{
	/* <div className="accordion mt-5" id="accordionExample">
										{faqsData?.map((item, index) => {
											return (
												<div className="accordion-item mb-4" key={index}>
													<h2 className="accordion-header" id="headingOne">
														<button
															className="accordion-button"
															type="button"
															data-toggle="collapse"
															data-target={`#${item?.question}${item?.id}`}
															aria-expanded="true"
															aria-controls={`${item?.question}${item?.id}`}
														>
															{item?.question}
														</button>
													</h2>
													<div
														id={`${item?.question}${item?.id}`}
														className="accordion-collapse collapse show"
														aria-labelledby={item?.id}
														data-bs-parent="#accordionExample"
													>
														<div className="accordion-body">{item?.answer}</div>
													</div>
												</div>
											);
										})}
									</div> */
}
