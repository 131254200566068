import React, { useEffect, useState } from "react";
import MainBanner from "../../Components/Banner/MainBanner";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import "../../assets/css/familymemberShip.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { arrow, dollar } from "../../constant";
import Apple from "../../assets/img/booking/garder_town/apple.png";
import google from "../../assets/img/booking/garder_town/google.png";
// import paypal from "../../assets/img/booking/garder_town/paypal.png";
import mastercard from "../../assets/img/booking/garder_town/mastercard.png";
import visa from "../../assets/img/booking/garder_town/visacard.png";
import StripeForm from "../../Components/PaymentMethod/StripeForm";
import ActionButton from "../../Components/ActionBtn/ActionButton";
import { GrAddCircle } from "react-icons/gr";
import { PostMembershipById } from "../../network/Network";

function FamilyMembership() {
  const styling = {
    marginTop: "10px",
    width: "30%",
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let [count, setCount] = useState([]);
  let [amount, setAmount] = useState(1);

  const [familyMembership, setFamilyMembership] = useState([]);

  const HandleClick = () => {
    setAmount(amount + 1);
    setCount([...count, amount]);
  };

  // Get Membership by id
  useEffect(() => {
    PostMembershipById(10)
      .then((res) => {
        console.log(res);
        setFamilyMembership(res?.data);
        // dispatch(AllCategoriesData(res?.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Header />
      <MainBanner name={familyMembership?.heading} />
      <section className="familymember-page">
        <div className="container">
          <div>
            <div className="Book_range_center">
              <h2 className="main-Title">1 Year Membership </h2>
              <h3 className="priceRate">
                <img src={dollar} alt="" />{familyMembership?.price}
              </h3>
            </div>
            <p className="main-para">{familyMembership?.description}</p>
            <ActionButton />
            <div className="formContainer">
              <p className="sub-title">Booking Details</p>
              <div className="row">
                <p className="members-para">Member 1</p>
                <div className="col-lg-6">
                  <p className="sub-title-sm">Full Name</p>
                  <input type="text" className="members-para" />
                </div>
                <div className="col-lg-6">
                  <p className="sub-title-sm">Email</p>
                  <input type="text" className="members-para" />
                </div>
                <div className="col-lg-6">
                  <p className="sub-title-sm mt-3">Contact Number</p>
                  <input type="text" className="members-para" />
                </div>
                <div className="col-lg-6">
                  <p className="sub-title-sm mt-3">Date Of birth</p>
                  <input type="date" className="members-para" />
                </div>

                <div className="col-lg-6">
                  <p className="sub-title-sm mt-3">Age</p>
                  <input type="number" className="members-para" />
                </div>

                <div className="col-lg-6 mt-3">
                  <p className="sub-title-sm">Gender</p>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="customradio mt-4">
                        <input type="radio" name="sdds" id="dsds" />
                        <label htmlFor="dsds">Male</label>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="customradio mt-4">
                        <input type="radio" name="sdds" id="female" />
                        <label htmlFor="female">Female</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <p className="sub-title-sm mt-3">Address</p>
                  <input type="text" className="members-para" />
                </div>
              </div>

              {count?.map((item, index) => {
                return (
                  <>
                    {index < 5 ? (
                      <div className="row">
                        <p className="members-para mt-4">Member {index + 2}</p>
                        <div className="col-lg-6">
                          <p className="sub-title-sm">Full Name</p>
                          <input type="text" className="members-para" />
                        </div>
                        <div className="col-lg-6">
                          <p className="sub-title-sm">Email</p>
                          <input type="text" className="members-para" />
                        </div>
                        <div className="col-lg-6">
                          <p className="sub-title-sm mt-3">Contact Number</p>
                          <input type="text" className="members-para" />
                        </div>
                        <div className="col-lg-6">
                          <p className="sub-title-sm mt-3">Date Of birth</p>
                          <input type="date" className="members-para" />
                        </div>

                        <div className="col-lg-6">
                          <p className="sub-title-sm mt-3">Age</p>
                          <input type="number" className="members-para" />
                        </div>

                        <div className="col-lg-6 mt-3">
                          <p className="sub-title-sm">Gender</p>
                          <div className="row">
                            <div className="col-md-3">
                              <div className="customradio mt-4">
                                <input type="radio" name="sdds" id="dsds" />
                                <label htmlFor="dsds">Male</label>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="customradio mt-4">
                                <input type="radio" name="sdds" id="female" />
                                <label htmlFor="female">Female</label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <p className="sub-title-sm mt-3">Address</p>
                          <input type="text" className="members-para" />
                        </div>
                      </div>
                    ) : null}
                  </>
                );
              })}

              <div className="col-lg-12 mt-4">
                <div
                  className="AddMemberform"
                  style={{ cursor: "pointer" }}
                  onClick={HandleClick}
                >
                  <p>Add New Member</p>
                  <GrAddCircle className="icons-add" />
                </div>
              </div>
              <p className="sub-title">Personal Information </p>
              <div className="camping_btn">
                <button className="btn">
                  SIGN IN
                  <img src={arrow} className="image-fluid pl-2" />
                </button>
                <button className="btn">
                  CREATE AN ACCOUNT
                  <img src={arrow} className="image-fluid pl-2" />
                </button>
              </div>

              {/* <p className="sub-title">Payment Details </p>
							<div class="card-type">
								<label class="container">
									<input
										type="radio"
										hidden
										checked="checked"
										name="payradio"
									/>
									<span class="checkmark">
										<figure>
											<img src={visa} class="img-fluid" />
										</figure>
									</span>
								</label>
								<label class="container">
									<input type="radio" hidden name="payradio" />
									<span class="checkmark">
										<figure>
											<img src={google} class="img-fluid" />
										</figure>
									</span>
								</label>
								<label class="container">
									<input type="radio" hidden name="payradio" />
									<span class="checkmark">
										<figure>
											<img src={mastercard} class="img-fluid" />
										</figure>
									</span>
								</label>
								<label class="container">
									<input type="radio" hidden name="payradio" />
									<span class="checkmark">
										<figure>
											<img src={Apple} class="img-fluid" />
										</figure>
									</span>
								</label>
							</div>
							<button className="btn mt-5">
								SUBMIT DETAILS
								<img src={arrow} className="image-fluid pl-2" />
							</button> */}
              <p className="sub-title">Payment Details </p>
              <StripeForm styling={styling} paynow="SUBMIT" />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default FamilyMembership;
