// Define api end points here
export const AUTH = "/auth";
export const REGISTER = "/register";
export const FORGOT = "/forgot-password";
export const BLOGS = "/blogs";
export const GET_HOHO_FAQS = "/getHohoFaqs";
export const HOHO_FARM = "/hoho-farm";
export const ADD = "/add";
export const NEWS_LETTER = "/news-letter";
export const GET_HOHO_SITE_SETTINGS = "/getHohoSiteSettings";
export const CUSTOMER = "/customer";
export const WISH_LIST = "/wish-list";
export const REMOVE = "/remove";
export const CATEGORIES = "/categories";
export const URLS = "/urls";
export const BRANDS = "/brands";
export const CAMPAIGN = "/campaign";
export const GET_CAMPAIGN = "/getCampaign";
export const FIND_CAMPAIGN = "/findCampaign";

export const DONATE = "/donate";
export const MAKE_DONATION = "/makeDonation";
export const MEMBERSHIP = "/membership";
export const GET_MEMBERSHIP = "/getMemberships";

export const FIND_MEMBERSHIP = "/findMembership";
export const CSA_PRODUCT_PRICE = "/csa-product-price";

export const PRODUCTS = "/products";
export const DETAILS = "/details";

export const SUBMIT = "/submit";
export const REVIEWS = "/reviews";
export const RATING = "/rating";

export const UPDATE_PROFILE = "/update-profile";
export const ADDRESS = "/address";
export const LIST = "/list";
export const CHANGE_PASSWORD = "/change-password";
export const ORDER = "/order";
export const BLOG_DETAILS = "/blog-details";
export const CONTACT = "/contact";
export const CONTACTUS = "/contactUs";
export const FILTER_TYPES = "/filter_types";
export const MY_DONATION = "/my-donation";
// HOHO END 


export const LOGIN = "/login";
export const RESET_PASSWORD = "/reset-password";
export const STATES = "/states";

export const AVAILABLE_FRANCHISES = "/available-franchises";
export const SITE_SETTINGS = "/site-settings"
export const PROFILE = "/profile"


export const FRANCHISE = "/franchises";
export const CREATE = "/create";
export const DETAIL = "/detail";
export const STATUS = "/status";

export const LEADS = "/leads";
export const PURCHASE = "/purchase";
export const INVESTMENT_LEVELS = "/investment-levels";
export const CARDS = "/cards";
export const PAGES = "/pages";
export const LEAD = "/lead";

export const CONSULTATION = "/consultation";
export const FORM = "/form";
export const DELETE = "/delete";

export const MAKE = "/make";
export const DEFAULT = "/default";
export const OUR_PROCESS = "/our-processes";

export const FRANCHISE_DETAILS = "/franchise-detail";
export const NEWSLETTER = "/newsletter";

export const UPDATE = "/update";
export const FRANCHISES = "/franchises";
export const DELETE_IMAGE = "/delete-image";
export const DELETE_VIDEO = "/delete-video";

export const PAGE = "?page";
export const FAQS = "/faqs";
export const QUESTIONS = "/questions";
export const SUBMIT_QUIZ_SECTION = "/submit-quiz-section";


// End Franchise 


export const STORE = "/store";



export const USER = "/user";
export const INFO = "/info";


export const CONTACT_STORE = "/contactStore";

export const RESTAURANT = "/restaurant";
export const GET_RESTAURANT = "/get-restaurant";
export const SEARCH_RESTAURANT = "/search-restaurant";
export const PROVINCE = "?province";
export const SEARCH = "&search";

export const CAREER = "/career";
export const GET_CAREER = "/get-career";
export const CAREER_DETAIL = "/career-detail";

export const GET_RATING = "/get-rating";
export const STORE_RATING = "/store-rating";



export const CATEGORY = "/category";
export const GET_CATEGORY = "/get-category";


export const GET_PAGES = "/get-pages";

export const FAQ = "/faq";
export const GET_FAQS = "/get-faqs";


export const GET_BLOG = "/get-blog";
export const BLOG_DETAIL = "/blog-detail";

export const CAREER_APPLICATION = "/career-application";
export const STORE_APPLICATION = "/store-application";

export const MENU = "/menu";
export const GET_MENU = "/get-menu";
export const CATEGORY_ID = "?category_id";
export const GET_MEDIA_FILES = "/get-media-files";

export const MY_ORDERS = "/my-orders";
export const PLACE_ORDER = "/place-order";
export const ORDER_CANCELLATION = "/order-cancellation";



export const STORE_ADDRESS = "/store-address";
export const GET_ADDRESS = "/get-address";
export const REMOVE_ADDRESS = "/remove-address";
export const UPDATE_ADDRESS = "/update-address";
export const GET_RATED_MENU = "/get-rated-menu";


export const SETTING = "/setting";
export const GET_SETTINGS = "/get-settings";

export const NEWS_LETTER_STORE = "/news-letter-store";



// end stacks
export const SPONSORS = "/sponsors";
export const EVENT = "/event";
export const EVENTS = "/events";


export const PACKAGE = "/package";
export const CAR = "/car";
export const CONDITION = "/condition";

export const OPTIONS = "/options";
export const COLOR = "/color";
export const INSTANTOFFER = "/instant-offer";

export const ALL_LISTING = "/all-listing";
export const CAR_CATEGORY_ID = "?car_category_id";
export const MAKE_ID = "&make_id";
export const MODEL_ID = "&model_id";

export const SHOWCASE_LISTING = "/showcase-listing";
export const POST = "/post";

export const ADD_LISTING = "/add-listing";
export const CONFIG = "/config";

export const BUSINESS_SETTINGS = "/business-settings";

export const PAYMENT_METHOD = "/payment-method";
export const SHOW_CARD = "/showCard";

export const REMOVE_CARD = "/removeCard";
export const UPDATE_DEFAULT_CARD = "/updateDefaultCard";

export const VIDEOS = "/videos"; 

export const CHAT = "/chat";
export const MESSAGE = "/message";
export const SEND = "/send";
export const GET = "/get";
export const SOCIAL_MEDIA = "/social-media";

export const GETINSTANTOFFER = "/getInstantOffer";

export const VIEW_CAR = "/view-car";
export const UPDATE_STATUS = "/updateStatus";
export const GET_EVENT = "/getEvent";

export const FAVOURITE = "/favourite";
export const LATEST = "/latest";
export const COUPON = "/coupon";
export const APPLY = "/apply";
export const CODE = "?code";
export const PLACE = "/place";
export const LOAN = "/loan";
export const CALCULATOR = "/calculator";
export const STORE_EVENT = "/store-event";

export const WISHLIST = "/wish-list";

export const EDIT = "/edit";

// end 

export const COMPANY_INFORMATION = "/company-information";



export const PRODUCT_ID = "?product_id";
export const HOTDEALS = "/hot-deals";
export const RELATED_PRODUCTS = "/related-products";
export const TRADING = "/trading";

export const TRACK = "/track";
export const REMOVE_ALL = "/remove_all";
export const CUSTOMER_ID = "?customer_id";
export const LINKS = "/links";


export const NAME = "?name";
export const PRICE = "/price";
export const START = "?start";
export const END = "end";
export const BANNERS = "/banners";
export const BANNER_TYPE = "?banner_type";
export const ADS = "/ads";
export const COUNTRY = "/country";
// export const FORGOT_PASSWORD = "/forgot-password";
export const TOP_PRODUCTS = "/top-products";

export const GET_PAGE = "/get_pages";
export const TYPE = "?type";
export const QUESTION_STORE = "/QuestionStore";




