import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function AllPlantsCard(props) {
	const { index, item, colValue, links } = props;
	const ImageUrlRedux = useSelector((state) => state.AuthReducer.urlData);
	return (
		<>
			<div className={colValue == "4" ? "col-lg-4" : "col-lg-3"} key={index}>
				<Link to={links}>
					{/* /product-details */}
					<div className="p-card">
						<div className="img-cont">
							<img src={`${ImageUrlRedux?.product_thumbnail_url}/${item?.thumbnail}`} alt="" className="image-fluid" />
						</div>
						<div className="content">
							<p className="title">{item?.name}</p>
							<p className="title2">${item?.unit_price}</p>
						</div>
					</div>
				</Link>
			</div>
		</>
	);
}

export default AllPlantsCard;
