import React, { useEffect, useState } from "react";
import MainBanner from "../../Components/Banner/MainBanner";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import "../../assets/css/FramersBooth.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { arrow } from "../../constant";
import Apple from "../../assets/img/booking/garder_town/apple.png";
import google from "../../assets/img/booking/garder_town/google.png";
// import paypal from "../../assets/img/booking/garder_town/paypal.png";
import mastercard from "../../assets/img/booking/garder_town/mastercard.png";
import visa from "../../assets/img/booking/garder_town/visacard.png";
import StripeForm from "../../Components/PaymentMethod/StripeForm";
import { dollar } from "../../constant";
import ActionButton from "../../Components/ActionBtn/ActionButton";
import { PostMembershipById } from "../../network/Network";

function FramersBooth() {
	const [farmerMembership, setFarmerMembership] = useState([]);

	const styling = {
		marginTop: "10px",
		width: "30%",
	};
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	  // Get Membership by id
	  useEffect(() => {
		PostMembershipById(9)
		  .then((res) => {
			console.log(res);
			setFarmerMembership(res?.data);
			// dispatch(AllCategoriesData(res?.data));
		  })
		  .catch((err) => {
			console.log(err);
		  });
	  }, []);
	return (
		<>
			<Header />
			<MainBanner name={"Framers Booth Space"} />
			<section className="Framers-page">
				<div className="container">
					<div>
						<div className="Book_range_center">
							<h2 className="main-Title">{farmerMembership?.heading} </h2>
							<h3 className="priceRate">
								<img src={dollar} alt="" /> {farmerMembership?.price}
							</h3>
						</div>
						{/* <h2 className="main-Title">Book Farmer’s Booth Space </h2> */}
						<p className="main-para">
							{farmerMembership?.description}
						</p>
						<ActionButton />
						<div className="formContainer">
							<p className="sub-title">Booking Details</p>
							<div className="row">
								<div className="col-lg-6">
									<p className="sub-title-sm">Select the Date</p>
									<input type="date" />
								</div>
								<div className="col-lg-6">
									<p className="sub-title-sm">Select Check-in Time</p>
									<input type="time" />
								</div>

								<div className="col-lg-6">
									<p className="sub-title-sm">Select location on farm</p>
									<select>
										<option>Select</option>
										<option>Garder Town</option>
										<option>Garder Town</option>
									</select>
								</div>
							</div>

							<p className="sub-title">Personal Information </p>
							<div className="camping_btn">
								<button className="btn">
									SIGN IN
									<img src={arrow} className="image-fluid pl-2" />
								</button>
								<button className="btn secondbtn">
									CREATE AN ACCOUNT
									<img src={arrow} className="image-fluid pl-2" />
								</button>
							</div>

							<p className="sub-title">Payment Details </p>
							<StripeForm styling={styling} paynow="SUBMIT" />
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
}

export default FramersBooth;
