import React from "react";
import { Rating } from "react-simple-star-rating";
import "../../assets/css/Reviews.css";
import { review, client1, client2, client3 } from "../../constant";
import moment from "moment";

function AllReviews(props) {
  const { reviewData } = props;

  console.log(reviewData)
  return (
    <>
      <div class="reviews_sec">
        <div class="heading">
          <h3>Reviews</h3>
        </div>
        {reviewData?.reviews?.map((item, index) => {
          return (
            <div class="reviewDv" key={index}>
              <div class="questionDv reviews">
                <div class="user-img">
                  <figure>
                    <img src={review} class="img-fluid" />
                  </figure>
                </div>
                <div class="detailDv">
                  <div class="header">
                    <h5 class="name">
                      {item?.user?.f_name} {item?.user?.l_name}{" "}
                    </h5>
                    <p>
                      {" "}
                      {moment(item?.created_at).format(
                        "MMMM Do YYYY, h:mm a"
                      )}
                    </p>
                  </div>
                  <p>{item?.comment}</p>
                  <div class="bottom-bar">
                    <div class="rating">
                      <Rating
                        size={18}
                        readonly={true}
                        ratingValue={
                          Math.round(item?.rating) === 1
                            ? "20"
                            : Math.round(item?.rating) === 2
                            ? "40"
                            : Math.round(item?.rating) === 3
                            ? "60"
                            : Math.round(item?.rating) === 4
                            ? "80"
                            : Math.round(item?.rating) === 5
                            ? "100"
                            : null
                        }
                      />
                      {/* <ul class="stars">
										<li>
											<i class="fa fa-star" aria-hidden="true"></i>
										</li>
										<li>
											<i class="fa fa-star" aria-hidden="true"></i>
										</li>
										<li>
											<i class="fa fa-star" aria-hidden="true"></i>
										</li>
										<li>
											<i class="fa fa-star-o" aria-hidden="true"></i>
										</li>
										<li>
											<i class="fa fa-star-o" aria-hidden="true"></i>
										</li>
									</ul> */}
                      <p class="count">{item?.rating}</p>
                    </div>
                    {/* <div class="rplyDv">
									<a href="#">Reply</a>
								</div> */}
                  </div>
                </div>
              </div>
              {/* <div class="answersDv">
						<div class="vertical-line"></div>
						<div class="answer reviews">
							<div class="user-img">
								<figure>
									<img src={client1} class="img-fluid" />
								</figure>
							</div>
							<div class="detailDv">
								<div class="header">
									<h5 class="name">Joeby Ragpa </h5>
									<p>- 12 April, 2014 at 16:50</p>
								</div>
								<p>
									We possess within us two minds. So far I have written only of
									the conscious mind. I would now like to introduce you to your
									second mind the hidden and mysterious subconscious.
								</p>
							</div>
						</div>
						<div class="answer reviews">
							<div class="user-img">
								<figure>
									<img src={client2} class="img-fluid" />
								</figure>
							</div>
							<div class="detailDv">
								<div class="header">
									<h5 class="name">Joeby Ragpa </h5>
									<p>- 12 April, 2014 at 16:50</p>
								</div>
								<p>
									We possess within us two minds. So far I have written only of
									the conscious mind. I would now like to introduce you to your
								</p>
							</div>
						</div>
						<div class="answer reviews">
							<div class="user-img">
								<figure>
									<img src={client3} class="img-fluid" />
								</figure>
							</div>
							<div class="detailDv">
								<div class="header">
									<h5 class="name">Joeby Ragpa </h5>
									<p>- 12 April, 2014 at 16:50</p>
								</div>
								<p>
									We possess within us two minds. So far I have written only of
									the conscious mind. I would now like to introduce you to your
									second mind,
								</p>
							</div>
						</div>
					</div> */}
            </div>
          );
        })}

        {/* <div class="reviewDv">
					<div class="questionDv reviews">
						<div class="user-img">
							<figure>
								<img src={review} class="img-fluid" />
							</figure>
						</div>
						<div class="detailDv">
							<div class="header">
								<h5 class="name">Joeby Ragpa </h5>
								<p>- 12 April, 2014 at 16:50</p>
							</div>
							<p>
								We possess within us two minds. So far I have written only of
								the conscious mind. I would now like to introduce you to your
								second mind, the hidden and mysterious subconscious. Our
								subconscious mind contains such power and complexity that it
								literally staggers the imagination.
							</p>
							<div class="bottom-bar">
								<div class="rating">
									<ul class="stars">
										<li>
											<i class="fa fa-star" aria-hidden="true"></i>
										</li>
										<li>
											<i class="fa fa-star" aria-hidden="true"></i>
										</li>
										<li>
											<i class="fa fa-star" aria-hidden="true"></i>
										</li>
										<li>
											<i class="fa fa-star-o" aria-hidden="true"></i>
										</li>
										<li>
											<i class="fa fa-star-o" aria-hidden="true"></i>
										</li>
									</ul>
									<p class="count">3.9</p>
								</div>
								<div class="rplyDv">
									<a href="#">Reply</a>
								</div>
							</div>
						</div>
					</div>
				</div> */}
      </div>
    </>
  );
}

export default AllReviews;
