import React, { useEffect, useState } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import "../../assets/css/Profile.css";
import SideBar from "../../Components/SideBar/SideBar";
import { Link } from "react-router-dom";
import TopBar from "../../Components/SideBar/TopBar";
import { cart1, notFound } from "../../constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faXmark } from "@fortawesome/free-solid-svg-icons";
import { GetAllWishList, PostWishListDelete } from "../../network/Network";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import NoRecord from "../../Components/NoRecord/NoRecord";
import { toast } from "react-toastify";
import { AddToCart } from "../../redux/actions/CartActions";
import swal from "sweetalert";

function Wishlist() {
  const dispatch = useDispatch();
  const Token = useSelector((state) => state.AuthReducer.token);
  const userData = useSelector((state) => state.AuthReducer.users);
  const ImageUrlRedux = useSelector((state) => state.AuthReducer.urlData);
  const cartDataRedux = useSelector((state) => state.CartReducer.cartData);

  const [fvtData, setFvtData] = useState([]);
  const [spinLoad, setSpinLoad] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [quantity, setQuantity] = useState(1);

  const handlePageClick = async (data) => {
    setCurrentPage(data?.selected + 1);
  };

  //Get wishlist Data
  useEffect(() => {
    setSpinLoad(true);
    GetAllWishList(currentPage, Token)
      .then((res) => {
        console.log("wish", res);
        setFvtData(res?.data?.data);
        const total = res?.data?.total;
        const limit = res?.data?.per_page;
        setPageCount(Math.ceil(total / limit));
        setSpinLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setSpinLoad(false);
      });
  }, [currentPage]);

  const DeleteWishListHandler = (id, index) => {
    setSpinLoad(true);
    let data = {
      product_id: id,
      _method: "delete",
    };
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this product!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        PostWishListDelete(data, Token)
          .then((res) => {
            console.log(res?.data?.data);
            setSpinLoad(false);
            setFvtData((preval) =>
              preval.filter((val) => val?.product?.id != id)
            );
          })
          .catch((err) => {
            console.log(err);
            setSpinLoad(false);
          });
      } else {
        swal("Your product is safe!");
        setSpinLoad(false);
      }
    });
  };

  const AddToCartHandler = (item) => {
    let checkItemAlreadyExist = cartDataRedux.filter(
      (val) => val?.id === item?.id
    );
    if (checkItemAlreadyExist.length > 0) {
      swal({
        title: "Item Already Exist in Cart!!!",
      });
      // alert("Item Already Exist in Cart!!!");
    } else {
      let data = {
        id: item?.id,
        price: item?.unit_price,
        quantity: quantity,
        productitem: item,
        deliveryDate: " ",
      };
      dispatch(AddToCart(data));
      toast("Product successfully added");
      setSpinLoad(true);
      let dataDelete = {
        product_id: item?.id,
        _method: "delete",
      };
      PostWishListDelete(dataDelete, Token)
        .then((res) => {
          console.log(res?.data?.data);
          setSpinLoad(false);
          setFvtData((preval) =>
            preval.filter((val) => val?.product?.id != item?.id)
          );
        })
        .catch((err) => {
          console.log(err);
          setSpinLoad(false);
        });
    }
  };

  return (
    <>
      <Header />

      {/* <!-- Universal Banner Sec End Here --> */}
      <section className="profile">
        <div className="container">
          <div className="row profile-row">
            <SideBar />
            <div className="col-lg-9">
              <div className="tab-content" id="v-pills-tabContent">
                <div
                  className="tab-pane fade active show"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <TopBar />

                  <div className="row">
                    <div className="col-lg-12 ">
                      <h3 className="form-heading heading-top-cont">
                        Wishlist
                      </h3>
                      {spinLoad ? (
                        <>
                        {[0, 1, 2, 3, 4, 5, 7, 8, 9]?.map((item, index) => {
                          return (
                            <div className="col-md-12 mt-3" key={index}>
                              <Skeleton count={5} />
                            </div>
                          );
                        })}
                      </>
                      ) : (
                        <>
                          {fvtData?.length > 0 ? (
                            <>
                              <div className="order-table-wrapper table-responsive">
                                <table>
                                  <tbody>
                                    <tr className="row1">
                                      <th className="order-no"></th>
                                      <th className="order-no">Product Name</th>
                                      <th className="order-date">Detail</th>
                                      <th className="order-status">PRICE</th>
                                      {/* <th className="order-status">SUBTOTAL</th> */}
                                      <th className="order-pay"></th>
                                    </tr>
                                    {fvtData?.map((item, index) => {
                                      return (
                                        <>
                                          <tr className="row2" key={index}>
                                            <td className="number">
                                              <img
                                               src={`${ImageUrlRedux?.product_thumbnail_url}/${item?.product?.thumbnail}`}
                                                alt=""
                                                className="image-fluid"
                                              />
                                            </td>
                                            <td className="date">
                                            {item?.product?.name}
                                            </td>
                                            <td className="date">
                                              Color: Green
                                              <br />
                                              Leaf Type: {item?.product?.leaf_type}
                                            </td>
                                            <td className="paid confirm">
                                              ${item?.product?.unit_price}{" "}
                                            </td>
                                            {/* <td className="total-price">$158.
                                    07 </td> */}

                                            <td className="order-details">
                                              <FontAwesomeIcon icon={faXmark} />
                                            </td>
                                          </tr>
                                        </>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            </>
                          ) : (
                            <NoRecord />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default Wishlist;
