import React, { useEffect, useState } from "react";
import Header from "../../Components/Header/Header";
import "../../assets/css/Login.css";
import { Link } from "react-router-dom";
import { arrow } from "../../constant";
import { useDispatch } from "react-redux";
import { login, WebSiteSettings } from "../../redux/actions/AuthActions";
import { toast } from "react-toastify";
import { GetSiteSettingsApi } from "../../network/Network";

function Login() {
	const dispatch = useDispatch()
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);
	const [checked, setChecked] = useState(false);
  
	const handleRememeberMe = (event) => {
	  setChecked(!checked);
	};

	const SignInHandler = async (e) => {
		e.preventDefault();
		setLoading(true);
		if (!email || !password) {
		  toast.error("Please Enter All Fields");
		  setLoading(false);
		  return;
		}
		if (
		  !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
			email
		  )
		) {
		  toast.error("Invalid Email");
		  setLoading(false);
		  return;
		} else {
		  localStorage.setItem("rememberMe", checked);
		  localStorage.setItem("email", checked ? email : "");
		  localStorage.setItem("password", checked ? password : "");
	
		  let data = {
			email: email,
			password: password,
		  };
		  console.log(data);
		  setTimeout(async () => {
			// setLoading(false);
			let x = await dispatch(login(data, setLoading));
			console.log("response login", x);
			if (x) {
			  console.log(x);
			  window.location.href = "/profile";
			  // Navigate("/profile");
			}
		  }, 600);
		}
	  };

	    // Get Pages Data
		useEffect(() => {
			GetSiteSettingsApi()
			  .then((res) => {
				console.log(res);
				dispatch(WebSiteSettings(res?.data?.data));
			  })
			  .catch((err) => {
				console.log(err);
			  });
		  }, []);

	return (
		<>
			<Header />
			<section className="login">
				<div className="login-card">
					<div className="main-title-cont">
						<hr className="hr-line" />
						<h2 className="main-title">Sign In</h2>
						<hr className="hr-line" />
					</div>
					<p className="inner-title">
						Welcome to back <span>Hoho Farms</span>
					</p>
					<div className="regs-cont">
						<p className="regs-text">Not a Member ?</p>
						<p className="regs-text">
							<Link to="/signup">Sign Up</Link>
						</p>
					</div>
					<div className="form-cont">
						<div className="mb-3">
							<label>Email</label>
							<input type="text" className="input-custom"
								 value={email}
								 onChange={(e) => setEmail(e.target.value)}
							/>
						</div>
						<div>
							<label>Password</label>
							<input type="password" className="input-custom" 
							 value={password}
							 onChange={(e) => setPassword(e.target.value)}
							/>
						</div>
						<div className="link-box">
							<div className="rem-cont">
								<input
									type="checkbox"
									id="vehicle1"
									name="vehicle1"
									// value="Bike"
									className="tick"
									value={checked}
									onChange={(e) => handleRememeberMe(e)}
								/>
								<p className="rem-text">Remember Me</p>
							</div>
							<div>
								<Link to="/forget-email">Forgot Password ?</Link>
							</div>
						</div>
						<div className="btn-cont mt-2">
							<button className="btn loginBtn"
							 onClick={(e) => SignInHandler(e)}
							 disabled={loading}
							>
								 {loading ? "Loading.." : " SIGN IN"} <img src={arrow} alt="" />
							</button>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default Login;
