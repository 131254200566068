import React, { useState } from "react";
import MainBanner from "../../Components/Banner/MainBanner";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import "../../assets/css/PlantingMixes.css";
import { n1, n2, n3, n4, notFound } from "../../constant";
import ProductCard from "../../Components/Cards/ProductCard";
import ReactPaginate from "react-paginate";
import { useEffect } from "react";
import { GetPlantsApi } from "../../network/Network";
import Skeleton from "react-loading-skeleton";
import NoRecord from "../../Components/NoRecord/NoRecord";
import { useSelector } from "react-redux";

function NutrientsAdditives() {
  const CategoriesReduxData = useSelector(
    (state) => state.AuthReducer.categories
  );
  const [nutritionData, setNutritionData] = useState([]);
  const [spinLoad, setSpinLoad] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState();

  const handlePageClick = async (data) => {
    setCurrentPage(data?.selected + 1);
  };

  // Get nutrients
  useEffect(() => {
    setSpinLoad(true);
    let data = {
      name: "/nutrients-additives",
    };
    GetPlantsApi(currentPage,data)
      .then((res) => {
        console.log(res);
        setNutritionData(res?.data?.data);
        const total = res?.data?.total;
        const limit = res?.data?.per_page;
        setPageCount(Math.ceil(total / limit));
        setSpinLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setSpinLoad(false);
      });
  }, [currentPage]);

  return (
    <>
      <Header />
      <MainBanner name={"Nutrients and Additives"} />
      <section className="plantingMixing-page">
        <div className="container">
          <div>
            <h2 className="main-Title">Browse From Our Best Planting Mixes </h2>
            <p className="main-para">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.{" "}
            </p>
          </div>
          <div className="row">
            {spinLoad ? (
               <>
               {[0, 1, 2, 3, 4, 5]?.map((item, index) => {
                 return (
                   <div className="col-md-4 mt-3" key={index}>
                     <Skeleton count={5} />
                   </div>
                 );
               })}
             </>
            ) : (
              <>
                {nutritionData?.length > 0 ? (
                  <>
                    {nutritionData?.map((item, index) => {
                      return (
                        <ProductCard 
                          item={item} index={index} 
                          colValue={"3"} 
                          pageLink={"/nutrients-additive-item"}
                        />
                      );
                    })}
                  </>
                ) : (
                 <NoRecord />
                )}
              </>
            )}
          </div>
          <div className="pagination-container mt-5">
            <ReactPaginate
              previousLabel="<"
              nextLabel=">"
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
              {nutritionData?.length > 0 ? (
                 <p className="Total-Product"> {nutritionData?.length} PRODUCTS 1 - {nutritionData?.length}</p>
                ) : null}
           
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default NutrientsAdditives;
