import React, { useEffect, useState } from "react";
import MainBanner from "../../Components/Banner/MainBanner";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import "../../assets/css/OurDonate.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import Image1 from "../../assets/img/ourdonor/donoer1.png";
import Image2 from "../../assets/img/ourdonor/donoer2.png";
import Image3 from "../../assets/img/ourdonor/donoer3.png";
import Image4 from "../../assets/img/ourdonor/donoer4.png";
import Image5 from "../../assets/img/ourdonor/donoer5.png";
import Image6 from "../../assets/img/ourdonor/donoer6.png";
import Image7 from "../../assets/img/ourdonor/donoer7.png";
import ActionButton from "../../Components/ActionBtn/ActionButton";
import { useNavigate } from "react-router-dom";

import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ChangingProgressProvider from "../../Components/DonateListing/ChangingProgressProvider";
import { PostCampaignDetails } from "../../network/Network";
import { ramdomImage } from "../../constant/ConstantFunction";

export const data = {
  datasets: [
    {
      label: ["My First dataset", "dssdds"],
      data: [60, 40],
      backgroundColor: ["#286152", "#FFFFFF"],
      borderColor: ["#fff"],
      borderWidth: 1,
    },
  ],
};
function OurDoners() {
  const navigate = useNavigate();
  let currentUrl = window.location.href.split("/");
  const [campaignDetails, setCampaignDetails] = useState([]);

  const percentage = 70;
  let radioImage = [
    {
      id: 1,
      image: Image3,
    },
    {
      id: 2,
      image: Image4,
    },
    {
      id: 3,
      image: Image5,
    },
    {
      id: 4,
      image: Image6,
    },
    {
      id: 5,
      image: Image7,
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    PostCampaignDetails(currentUrl[4])
      .then((res) => {
        console.log("camp details", res);
        setCampaignDetails(res?.data);
        // dispatch(AllStates(res?.data?.response?.data))
      })
      .catch((err) => {
        console.log(err);
      });
  }, [currentUrl[4]]);

  console.log(campaignDetails)
  return (
    <>
      <Header />
      <MainBanner name={"Campaign"} />
      <section className="OurDonate-page">
        <div className="container">
          <div className="first-section">
            <div className="row">
              <div className="col-lg-6 col-xs-12 col-sm-12 ImagesPlants">
                <img
                  src={Image1}
                  alt="save-nature-image"
                  className="img-fluid"
                />
              </div>
              <div className="col-lg-6 col-xs-12 col-sm-12">
                <h2 className="main-Title">
                  {campaignDetails?.campaign?.title}
                </h2>
                <p className="main-para">
                  {campaignDetails?.campaign?.description}
                </p>
                <ActionButton />
                <div className="amount_circle">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="amount-set">
                        {/* <p className="first-amount">$46638.458</p>
									<p className="second-amount">$50000.458</p> */}
                        <div style={{ width: "90%" }} className="circle-div">
                          <ChangingProgressProvider
                            values={[40, 50, 60, 70, 100]}
                          >
                            {(percentage) => (
                              <CircularProgressbar
                                value={percentage}
                                strokeWidth={50}
                                styles={buildStyles({
                                  strokeLinecap: "butt",

                                  pathColor: "#286152",
                                  trailColor: "#fff",
                                })}
                              />
                            )}
                          </ChangingProgressProvider>
                          <div className="right_circle">
                            <p>Donated</p>
                            <h4>${campaignDetails?.total_donation}</h4>
                          </div>
                          <div className="left-circle">
                            <p>Goal</p>
                            <h4>${campaignDetails?.campaign?.goal_price}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="donated-amount">
                        <div className="goal">
                          <div className="goal-amount">
                            <h6>${campaignDetails?.total_donation}</h6>
                            <p>Goal</p>
                          </div>
                        </div>
                        <div className="goal mt-3 border-set">
                          <div className="goal-amount">
                            <h6>${campaignDetails?.campaign?.goal_price}</h6>
                            <p>Donated</p>
                          </div>
                        </div>
                        <div className="goal mt-3">
                          <div className="goal-amount">
                            <h6>{campaignDetails?.donors?.length}</h6>
                            <p>Doners</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  className="btn doners-btn"
                  onClick={() =>navigate(`/donate`, {state: {data: currentUrl[4]}})} 
                >
                  DONATE NOW
                </button>
              </div>
            </div>
          </div>
          <div className="second-section mt-5">
            <div className="row">
              <div className="col-lg-6">
                <h2 className="main-Title">
                  {campaignDetails?.campaign?.middle_section_heading}
                </h2>
                <p className="main-para">
                  {campaignDetails?.campaign?.middle_section_description}
                </p>
              </div>
              <div className="col-lg-6">
                <img src={Image2} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
          <div className="third-section">
            <h2 className="main-Title2">Our Doners</h2>
            <p className="main-para2">
              Proin libero nunc consequat interdum varius sit amet mattis
              vulputate. Sit amet justo donec enim diam vulputate ut pharetra.
              Massa id neque aliquam vestibulum morbi. Maecenas pharetra
              convallis posuere morbi leo. Mattis nunc sed blandit libero
            </p>
            <div className="zaam">
              {campaignDetails?.donors?.map((item, index) => (
                <>
                  <div className="main-donour">
                    {item?.user?.image == null ? (
                      <img
                        src={`${ramdomImage(
                          `${item?.user?.f_name}{""}${item?.user?.l_name}`
                        )}`}
                        className="image-fluid image-width"
                        style={{ borderRadius: "50%", width: "100%" }}
                        alt=""
                      />
                    ) : (
                      <img
                        src={item?.user?.image}
                        alt="all-Images"
                        className="img-fuild"
                      />
                    )}
                  </div>
                </>
              ))}
            </div>
            <button className="btn doners-btn btn2"
				onClick={() => navigate(`/donate`, {state: {data: currentUrl[4]}})}

			>DONATE NOW</button>

          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default OurDoners;
