import logo from "./logo.svg";
import "./assets/css/Style.css";
import Header from "./Components/Header/Header";
import PublicRoutes from "./routes/PublicRoutes";
import "animate.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import 'react-loading-skeleton/dist/skeleton.css'

function App() {
	return (
		<>
			<PublicRoutes />
			<ToastContainer />
		</>
	);
}

export default App;
