import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function ProductCard(props) {
	const { index, item, colValue,pageLink } = props;
	const ImageUrlRedux = useSelector((state) => state.AuthReducer.urlData);
	console.log(pageLink)
	return (
		<>
			<div
				className={colValue == "4" ? "col-lg-4" : "col-lg-3 set_card"}
				key={index}
			>
				<Link to={`${pageLink}/${item?.id}`}>
					<div className="p-card">
						<div className="img-cont">
							<img src={`${ImageUrlRedux?.product_thumbnail_url}/${item?.thumbnail}`} alt="" className="image-fluid" />
						</div>
						<div className="content">
							<p className="title">{item?.name}</p>
							{/* <p className="title2">$250.00</p> */}
						</div>
					</div>
				</Link>
			</div>
		</>
	);
}

export default ProductCard;
