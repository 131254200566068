import React, { useState } from "react";
import MainBanner from "../../Components/Banner/MainBanner";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import { useNavigate } from "react-router-dom";
import "../../assets/css/BookingMember.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  booking1,
  booking2,
  booking3,
  booking4,
  booking5,
  notFound,
} from "../../constant";
import ActionButton from "../../Components/ActionBtn/ActionButton";
import { style } from "@mui/system";
import { useEffect } from "react";
import { GetMembership } from "../../network/Network";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";

function BookingMemberShip() {
  const navigate = useNavigate();
  const ImageUrlRedux = useSelector((state) => state.AuthReducer.urlData);
  const [bookingMembership, setBookingMembership] = useState([]);
  const [spinLoad, setSpinLoad] = useState(false);

  // Get Membership Data
  useEffect(() => {
    setSpinLoad(true);
    GetMembership()
      .then((res) => {
        console.log(res);
        setBookingMembership(res?.data);
        setSpinLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setSpinLoad(false);
      });
  }, []);

  const SelectMembershipHandler = (item) => {
    if (item?.heading == "Family Membership") {
      navigate("/family-membership");
      return;
    }
    if (item?.heading == "Farmer’s Booth Space") {
      navigate("/framers-booth");
      return;
    }
    if (item?.heading == "CSA Membership") {
      navigate("/csa-membership");
      return;
    }
    if (item?.heading == "Camping") {
      navigate("/camping");
      return;
    }
    if (item?.heading == "Garden Tour") {
      navigate("/garden-tour");
      return;
    }
  };

  return (
    <>
      <Header />
      <MainBanner name={"Bookings & Memberships"} />
      <section className="bookingMember-page">
        <div className="container">
          <div>
            <h2 className="main-Title">Buy from Our Best Membership</h2>
            <p className="main-para">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </p>
          </div>

          <div className="row">
            {spinLoad ? (
              <>
                <div className="col-md-4">
                  <Skeleton count={5} />
                </div>
                <div className="col-md-4">
                  <Skeleton count={5} />
                </div>
                <div className="col-md-4">
                  <Skeleton count={5} />
                </div>
              </>
            ) : (
              <>
                {bookingMembership?.length > 0 ? (
                  <>
                    {bookingMembership?.slice(0, 3)?.map((item, index) => {
                      return (
                        <div
                          className="col-lg-4 col-sm-6"
                          key={index}
                          onClick={(e) => SelectMembershipHandler(item)}
                          style={{ cursor: "pointer" }}
                        >
                          <div className="member-card">
                            <div>
                              <img
                                src={`${ImageUrlRedux?.membership_image_url}/${item?.image}`}
                                alt=""
                                className="image-fluid"
                              />
                            </div>
                            <p className="title">{item?.heading}</p>
                          </div>
                        </div>
                      );
                    })}

                    <div className="col-lg-2"></div>
                    {bookingMembership?.map((item, index) => {
                      return (
                        <>
                          {index > 2 ? (
                            <div
                              className="col-lg-4 col-sm-6"
                              key={index}
                              onClick={() => SelectMembershipHandler(item)}
                              style={{ cursor: "pointer" }}
                            >
                              <div className="member-card">
                                <div>
                                  <img
                                    src={`${ImageUrlRedux?.membership_image_url}/${item?.image}`}
                                    alt=""
                                    className="image-fluid"
                                  />
                                </div>
                                <p className="title">{item?.heading}</p>
                              </div>
                            </div>
                          ) : null}
                        </>
                      );
                    })}
                  </>
                ) : (
                  <div className="notFound-container">
                    <img
                      src={notFound}
                      alt="not found"
                      className="image-fluid"
                    />
                    <p className="mt-4">No Reacord Found</p>
                  </div>
                )}
              </>
            )}

            <div className="col-lg-2"></div>
          </div>
        </div>
      </section>
      <ActionButton />
      <Footer />
    </>
  );
}

export default BookingMemberShip;
