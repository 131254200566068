import React, { useEffect, useState } from "react";
import MainBanner from "../../Components/Banner/MainBanner";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import "../../assets/css/GardenTour.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { arrow, dollar } from "../../constant";
import Apple from "../../assets/img/booking/garder_town/apple.png";
import google from "../../assets/img/booking/garder_town/google.png";
// import paypal from "../../assets/img/booking/garder_town/paypal.png";
import mastercard from "../../assets/img/booking/garder_town/mastercard.png";
import visa from "../../assets/img/booking/garder_town/visacard.png";
import StripeForm from "../../Components/PaymentMethod/StripeForm";
import ActionButton from "../../Components/ActionBtn/ActionButton";
import { useSelector } from "react-redux";
import { PostMembershipById } from "../../network/Network";

function GardenTour() {
	const ImageUrlRedux = useSelector((state) => state.AuthReducer.urlData);
	const [gardenMembership, setGardenMembership] = useState([]);
	const styling = {
		marginTop: "10px",
		width: "30%",
	};
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	 // Get Membership by id
	 useEffect(() => {
		PostMembershipById(1)
		  .then((res) => {
			console.log(res);
			setGardenMembership(res?.data);
			// dispatch(AllCategoriesData(res?.data));
		  })
		  .catch((err) => {
			console.log(err);
		  });
	  }, []);

	return (
		<>
			<Header />
			<MainBanner name={"Garden Tour"} />
			<section className="GardenTour-page">
				<div className="container">
					<div>
						<div className="Book_range_center">
							<h2 className="main-Title">Book 1 Day Tour </h2>
							<h3 className="priceRate">
								<img src={dollar} alt="" />{gardenMembership?.price} 
							</h3>
						</div>
						<p className="main-para">
						{gardenMembership?.description} 
						</p>
						<ActionButton />

						<div className="formContainer">
							<p className="sub-title">Booking Details</p>
							<div className="row">
								<div className="col-lg-6">
									<p className="sub-title-sm">Select the Date</p>
									<input type="date" />
								</div>
								<div className="col-lg-6">
									<p className="sub-title-sm">Select Check-in Time</p>
									<input type="time" />
								</div>
								<div className="col-lg-6 addCampingRadio">
									<div className="row">
										{/* <div className="col-md-2 centerRadioBtn"></div> */}
										<div className="col-md-12">
											<p className="sub-title-sm with_check">
												<input type="checkbox" />
												<span>
													Add Camping to the package. (extra charges included)
												</span>
											</p>
										</div>
									</div>
								</div>
								<div className="col-lg-6 addCampingRadio">
									<div className="row">
										{/* <div className="col-md-2 centerRadioBtn"></div> */}
										<div className="col-md-12">
											{/* <p className="sub-title-sm ">Gender</p> */}
											<label
												htmlFor="gender"
												className="for_radio sub-title-sm"
											>
												<input type="radio" id="gender" />
												<span>I have a Family Membership</span>
											</label>
										</div>
									</div>
								</div>
							</div>

							<p className="sub-title">Personal Information </p>
							<div className="camping_btn">
								<button className="btn">
									Sign In
									<img src={arrow} className="image-fluid pl-2" />
								</button>
								<button className="btn secondbtn">
									Create An Account
									<img src={arrow} className="image-fluid pl-2" />
								</button>
							</div>

							<p className="sub-title">Payment Details </p>
							<StripeForm styling={styling} paynow="SUBMIT" />
							{/* <button className="btn mt-5">
								SUBMIT DETAILS
								<img src={arrow} className="image-fluid pl-2" />
							</button> */}
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
}

export default GardenTour;
