import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import "../../assets/css/Footer.css";
import { arrow2 } from "../../constant";
import { GetBrands, GetUrls } from "../../network/Network";
import { AllBrands, AllURls } from "../../redux/actions/AuthActions";

function Footer() {
  const dispatch = useDispatch();

  // Get Pages Data
  useEffect(() => {
    GetUrls()
      .then((res) => {
        console.log(res);
        dispatch(AllURls(res?.data?.base_urls));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Get brands Data
  useEffect(() => {
    GetBrands()
      .then((res) => {
        console.log("brands",res);
        dispatch(AllBrands(res?.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  
  return (
    <section className="Footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 footer_padding">
            <h2 className="main-title">Newsletter</h2>
            <div>
              <input type="text" placeholder="Write Your Email" />
            </div>
            <div>
              <button className="btn book-btn buttunset">
                SUBMIT
                <img src={arrow2} className="image-fluid" alt="" />{" "}
              </button>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-lg-4 ">
                <h2 className="main-title">Quick Links</h2>
                <div>
                  <Link to="/contact">Contact Us</Link>
                </div>
                <div>
                  <Link to="/about-us"> About Us</Link>
                </div>
                <div>
                  <Link to="/blogs">Blogs</Link>
                </div>
                {/* <div>
									<Link to="/faqs">FAQ</Link>
								</div>
								<div>
									<Link to="/">Our Plant Sizes</Link>
								</div>
								<div>
									<Link to="/">Planting Guide</Link>
								</div>
								<div>
									<Link to="/">Customer Reviews</Link>
								</div> */}
              </div>
              <div className="col-lg-4">
                <h2 className="main-title margin_footer">Extras</h2>
                {/* <div>
									<Link to="/about-us"> About Us</Link>
								</div> */}
                {/* <div>
									<Link to="/blogs">Blogs</Link>
								</div> */}
                <div>
                  <Link to="/testimonial">Testimonials</Link>
                </div>
                <div>
                  <Link to="/terms-conditions">Terms & Condition</Link>
                </div>
                <div>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </div>
                <div>
                  <Link to="/cancelation">Cancelation Policy</Link>
                </div>
              </div>
              <div className="col-lg-4">
                <h2 className="main-title margin_footer">PRODUCT SEARCH</h2>
                <div>
                  <Link to="/">Conifers</Link>
                </div>
                <div>
                  <Link to="/">Maples</Link>
                </div>
                <div>
                  <Link to="/">Ginkgo Biloba</Link>
                </div>
                <div>
                  <Link to="/">Other Plants</Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div>
              <h2 className="big-title">HoHo Farms</h2>
            </div>
            <div>
              <p className="copy-right">
                Copyrights © 2022 Hoho. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;
