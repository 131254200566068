
export const ApiUrl =
"http://kigi-nursery.developer-ourbase-camp.com/api/v1" ||
"http://localhost:4000";





// export const ApiUrl =
// "http://http://192.168.18.140/franchise_velocity/public/api/v1" ||
// "http://localhost:4000";



