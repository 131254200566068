import React, { useEffect } from "react";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import { village1 } from "../../constant";
import "../../assets/css/TouristsLove.css";
import { GetBlogDetails } from "../../network/Network";
import { useState } from "react";
import { useSelector } from "react-redux";

function Touristslove() {
	const ImageUrlRedux = useSelector((state) => state.AuthReducer.urlData);
    let currentUrl = window.location.href.split("/");
    const [blogDetails, setBlogDetails] = useState();
    const [spinLoad, setSpinLoad] = useState(false);

    useEffect(() => {
        setSpinLoad(true);
        GetBlogDetails(currentUrl[4])
          .then((res) => {
            console.log("details", res);
            setBlogDetails(res?.data?.data);
            setSpinLoad(false);
          })
          .catch((err) => {
            console.log(err);
            setSpinLoad(false);
          });
      }, [currentUrl[4]]);

	useEffect(() => {
		window.scrollTo(0, 0)
	  }, [])
	return (
		<>
			<Header />
			<section className="imageContainer">
				<div className="blog-cover-img">
				<img src={`${ImageUrlRedux?.blog_url}/${blogDetails?.image}`} alt="village" className="img-fluid" />
				</div>
				<div className="container">
					<div className="row">
						<div className="col-lg-1"></div>
						<div className="col-lg-10 main-touristlover-div">
							<h6 className="tourists-title">
							{blogDetails?.title}
							</h6>
							<p
                              dangerouslySetInnerHTML={{
                                __html: blogDetails?.content,
                              }}
                            />
						</div>
						<div className="col-lg-1"></div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
}

export default Touristslove;
