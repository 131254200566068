import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Aboutus from "../pages/About/Aboutus";
import ForgotEmail from "../pages/Auth/ForgotEmail";
import Login from "../pages/Auth/Login";
import Signup from "../pages/Auth/Signup";
import Blogs from "../pages/Blogs/Blogs";
import BookingMemberShip from "../pages/BookingMemberShip/BookingMemberShip";
import GardenTour from "../pages/BookingMemberShip/GardenTour";
import Camping from "../pages/BookingMemberShip/Camping";
import FramersBooth from "../pages/BookingMemberShip/FramersBooth";
import Cart from "../pages/Cart/Cart";
import Checkout from "../pages/Cart/Checkout";
import Contactus from "../pages/Contact/Contactus";
import Donate from "../pages/Donate/Donate";
import Faqs from "../pages/Faqs/Faqs";
import Home from "../pages/Home/Home";
import PrivacyPolicy from "../pages/PolicyPages/PrivacyPolicy";
import Touristslove from "../pages/PolicyPages/Touristslove";
import Terms from "../pages/PolicyPages/Terms";
import AllPlants from "../pages/Products/AllPlants";
import NutrientsAdditives from "../pages/Products/NutrientsAdditives";
import PlantingMixes from "../pages/Products/PlantingMixes";
import ProductDetails from "../pages/Products/ProductDetails";
import Products from "../pages/Products/Products";
import MyBooking from "../pages/Profile/MyBooking";
import MyDonation from "../pages/Profile/MyDonation";
import MyMemberShip from "../pages/Profile/MyMemberShip";
import OrdersDetails from "../pages/Profile/OrdersDetails";
import MyOrders from "../pages/Profile/MyOrders";
import Profile from "../pages/Profile/Profile";
import Wishlist from "../pages/Profile/Wishlist";
import NotFound from "../pages/404 Pages/NotFound";

import ProtectedRoutes from "./ProtectedRoutes";
import CSAMEMBERship from "../pages/BookingMemberShip/CSAMembership";
import MyAddress from "../pages/Profile/MyAddress";
import OurDoners from "../pages/OurDoners/OurDoners";
import Testimonial from "../pages/Testimonial/Testimonial";
import Cancelation from "../pages/PolicyPages/Cancelation";
import QAScanner from "../Components/QAScanner/QAScanner";
import QACode from "../pages/Profile/QACode";
import OrderDetails from "../pages/BookingCard/BookingCard";
import OrderDetail from "../pages/OrderDetails/OrderDetails";
import MyDonationDetails from "../pages/My Donation/MyDonation";
import MyBookings from "../pages/MyBooking/MyBookings";
import OrderDetailSlider from "../Components/Slider/OrderDetailSlider";
import GeneralHydroponic from "../pages/Products/GeneralHydroponic";
import FamilyMembership from "../pages/BookingMemberShip/FamilyMembership";
import Thankyou from "../Components/ThankYouPage/Thankyou";
import OrderViewDetails from "../pages/OrderViewDetails/OrderViewDetails";
import DonateListing from "../Components/DonateListing/DonateListing";
import BlogDetail from "../pages/Blogs/BlogsDetails";
import OtpPage from "../pages/Auth/OtpPage";
import NewPassword from "../pages/Auth/NewPassword";
import ProductsDetailPage from "../pages/Products/ProductsDetailPage";
import CSAmemberdetail from "../pages/Profile/CSAmemberdetail";
import FamilyMembershipDetail from "../pages/Profile/familyMembershipDetail";

function PublicRoutes(props) {
	return (
		<>
			<BrowserRouter>
				<Routes>


					<Route path="/" element={<Home />} />
					<Route path="blogs" element={<Blogs />} />
					<Route path="blog-details/:Id" element={<Touristslove />} />
					{/* <Route path="blog-details" element={<BlogDetail />} /> */}
					<Route path="all-plants" element={<AllPlants />} />
					<Route path="product-details" element={<ProductDetails />} />
					
					<Route path="nutrients-additive-item/:id" element={<GeneralHydroponic />} />
					<Route path="planting-mixes-item/:id" element={<GeneralHydroponic />} />

					<Route path="products" element={<Products />} />
					<Route path="products-detailPage" element={<ProductsDetailPage />} />
					<Route path="planting-mixes" element={<PlantingMixes />} />
					<Route path="nutrients-additives" element={<NutrientsAdditives />} />
					
					
					<Route path="booking-membership" element={<BookingMemberShip />} />
					<Route path="garden-tour" element={<GardenTour />} />
					<Route path="camping" element={<Camping />} />
					<Route path="framers-booth" element={<FramersBooth />} />
					<Route path="family-membership" element={<FamilyMembership />} />
					<Route path="csa-membership" element={<CSAMEMBERship />} />
					<Route path="All-Order-Detail" element={<OrderViewDetails />} />
					<Route path="donate-listing" element={<DonateListing />} />
					<Route path="our-doners/:id" element={<OurDoners />} />
					<Route path="donate" element={<Donate />} />
					
					<Route path="contact" element={<Contactus />} />
					<Route path="about-us" element={<Aboutus />} />
					
					<Route path="cart" element={<Cart />} />
					<Route path="check-out" element={<Checkout />} />
					<Route path="thankyou" element={<Thankyou />} />
					<Route path="faqs" element={<Faqs />} />
					<Route path="privacy-policy" element={<PrivacyPolicy />} />
					
					<Route path="testimonial" element={<Testimonial />} />
					<Route path="terms-conditions" element={<Terms />} />
					<Route path="cancelation" element={<Cancelation />} />
					
					<Route path="donation" element={<MyDonationDetails />} />
					<Route path="booking-details" element={<MyBookings />} />

					<Route path="login" element={<Login />} />
					<Route path="signup" element={<Signup />} />
					<Route path="forget-email" element={<ForgotEmail />} />
					<Route path="otp" element={<OtpPage />} />
					<Route path="new-password" element={<NewPassword />} />

					<Route path="/" element={<ProtectedRoutes />}>
						<Route path="profile" element={<Profile />} />
						<Route path="address" element={<MyAddress />} />
						<Route path="my-orders" element={<MyOrders />} />
						<Route path="my-bookings" element={<MyBooking />} />
						<Route path="my-membership" element={<MyMemberShip />} />
						<Route path="CSA-member" element={<CSAmemberdetail />} />
						<Route
							path="family-member-details"
							element={<FamilyMembershipDetail />}
						/>
						<Route path="orders-details" element={<OrdersDetails />} />
						<Route path="my-donations" element={<MyDonation />} />
						<Route path="wishlist" element={<Wishlist />} />
						<Route path="scanner" element={<QACode />} />
						<Route path="order-details" element={<OrderDetail />} />
					</Route>

					<Route path="/*" element={<NotFound />} />
				</Routes>
			</BrowserRouter>
		</>
	);
}

export default PublicRoutes;
