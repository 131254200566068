import React, { useState } from "react";
import MainBanner from "../../Components/Banner/MainBanner";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import { handbag, shirt, shp } from "../../constant";
import "../../assets/css/AllPlants.css";
import ProductCard from "../../Components/Cards/ProductCard";
import ReactPaginate from "react-paginate";
import ActionButton from "../../Components/ActionBtn/ActionButton";
import AllPlantsCard from "./AllPlantsCard";
import { useEffect } from "react";
import { GetAllFilterApi, GetPlantsApi } from "../../network/Network";
import Skeleton from "react-loading-skeleton";
import NoRecord from "../../Components/NoRecord/NoRecord";
import { useSelector } from "react-redux";

function Products() {
  const CategoriesReduxData = useSelector(
    (state) => state.AuthReducer.categories
  );
  const [productsData, setProductsData] = useState([]);
  const [spinLoad, setSpinLoad] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState();

  const [startPrice, setStartPrice] = useState("");
  const [endPrice, setEndPrice] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [filtersData, setFiltersData] = useState([]);
  const [search, setSearch] = useState("");

  const handlePageClick = async (data) => {
    setCurrentPage(data?.selected + 1);
  };

  const ApplyFilters = (e = null, type) => {
    if (e) {
      e.preventDefault();
    }
    setSpinLoad(true);
    let data;
    if (type == "reset") {
      data = {
        name: "/other-products",
        start_price: " ",
        end_price: " ",
        category: " ",
        search: " ",
      };
      setStartPrice("");
      setEndPrice("");
      setSelectedCategory("");
      setSearch("");
    } else {
      data = {
        name: "/other-products",
        start_price: type == "search" ? " " : startPrice,
        end_price: type == "search" ? " " : endPrice,
        category: type == "search" ? " " : selectedCategory,
        search: type == "filter" ? " " : search,
      };
    }
    GetPlantsApi(currentPage,data)
      .then((res) => {
        console.log(res);
        setProductsData(res?.data?.data);
        const total = res?.data?.total;
        const limit = res?.data?.per_page;
        setPageCount(Math.ceil(total / limit));
        setSpinLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setSpinLoad(false);
      });
  };

  // Get ALL Products
  useEffect(() => {
    ApplyFilters(null);
  }, [currentPage]);

  // Get FILTERS Data
  useEffect(() => {
    GetAllFilterApi()
      .then((res) => {
        console.log("filter ", res);
        setFiltersData(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const cateData = [
    {
      id: 1,
    },
    {
      id: 2,
    },
    {
      id: 3,
    },
    {
      id: 4,
    },
  ];
  return (
    <>
      <Header />
      <MainBanner name={"Products"} />
      <section className="allPlants-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="filter-side">
                <div className="title-cont">
                  <h2 className="filter-title">Apply Filters</h2>
                </div>
                <div className="search-bar-cont">
                  <input type="text" placeholder="Serach" className="search" 
                   value={search}
                   onChange={(e) => setSearch(e.target.value)}
                  />
                  {/* onClick={(e) => ApplyFilters(e, "search")} */}
                </div>
                <p className="maincate-head">Category</p>
                {cateData?.map((item, index) => {
                  return (
                    <div className="cate-box" key={index}>
                      <p className="title">Lorem</p>
                      <input type="checkbox" value="Bike"></input>
                    </div>
                  );
                })}

                <ActionButton />
                <p className="load-more">LOAD MORE</p>

                <p className="maincate-head">Price</p>
                <div className="row">
                  <div className="col-lg-6">
                    <label className="price-text">From</label>
                    <input
                      type="number"
                      className="price-filter"
                      placeholder="From"
                          value={startPrice}
                          onChange={(e) => setStartPrice(e.target.value)}
                    />
                  </div>
                  <div className="col-lg-6">
                    <label className="price-text">To</label>
                    <input
                      type="number"
                      className="price-filter"
                      placeholder="To"
                      value={endPrice}
                      onChange={(e) => setEndPrice(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="row">
                {spinLoad ? (
                  <>
                  {[0, 1, 2, 3, 4, 5]?.map((item, index) => {
                    return (
                      <div className="col-md-4 mt-3" key={index}>
                        <Skeleton count={5} />
                      </div>
                    );
                  })}
                </>
                ) : (
                  <>
                    {productsData?.length > 0 ? (
                      <>
                        {productsData?.map((item, index) => {
                          return (
                            <AllPlantsCard
                              item={item}
                              index={index}
                              colValue={"4"}
                              links="/products-detailPage"
                            />
                          );
                        })}
                      </>
                    ) : (
                      <NoRecord />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="pagination-container mt-5">
            <ReactPaginate
              previousLabel="<"
              nextLabel=">"
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
            {productsData?.length > 0 ? (
               <p className="Total-Product">{productsData?.length} PRODUCTS 1 - {productsData?.length}</p>
                  
                ) : null}
           
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Products;
