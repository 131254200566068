import React, { useEffect, useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "../../assets/css/DonatedListing.css";
import ChangingProgressProvider from "./ChangingProgressProvider";
import { CardImage1, CardImage2, notFound } from "../../constant";
import Header from "../Header/Header";
import MainBanner from "../Banner/MainBanner";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";
import { GetCampaign } from "../../network/Network";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";

function DonateListing() {
  const ImageUrlRedux = useSelector((state) => state.AuthReducer.urlData);
  const percentage = 66;
  const navigate = useNavigate();
  const [campaign, setCampaign] = useState([]);
  const [spinLoad, setSpinLoad] = useState(false);

  const HandelNavigateDetail = (e,id) => {
	e.preventDefault()
    navigate(`/our-doners/${id}`);
  };

  // Get c Data
  useEffect(() => {
    setSpinLoad(true);
    GetCampaign()
      .then((res) => {
        console.log(res);
        setCampaign(res?.data);
        // dispatch(AllCategoriesData(res?.data));
        setSpinLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setSpinLoad(false);
      });
  }, []);


  return (
    <>
      <Header />
      <MainBanner name={"Campaigns"} />
      <section className="Donate-listing">
        <div class="container">
          <div class="row">
            {spinLoad ? (
              <>
                <div className="col-md-4">
                  <Skeleton count={5} />
                </div>
                <div className="col-md-4">
                  <Skeleton count={5} />
                </div>
				<div className="col-md-4">
                  <Skeleton count={5} />
                </div>
              </>
            ) : (
              <>
                {campaign?.length > 0 ? (
                  <>
                    {campaign?.map((item, index) => {
                      return (
                        <div class="col-md-4" onClick={(e)=>HandelNavigateDetail(e,item?.id)}> 
                          <div class="dntn_prod">
                            <div>
                              <a href="#">
                                <img
                                  src={`${ImageUrlRedux?.campaign_image_url}/${item?.image}`}
                                  class="img-fluid"
                                  alt=""
                                />
                              </a>
                            </div>
                            <div class="text-center">
                              <h4>{item?.title}</h4>
                            </div>
                            <div class="dntn_prod_desc">
                              <div style={{ width: "40px" }}>
                                <ChangingProgressProvider
                                  values={[0, 20, 40, 60, 80, 100]}
                                >
                                  {(percentage) => (
                                    <CircularProgressbar
                                      value={percentage}
                                      text={`${percentage}%`}
                                    />
                                  )}
                                </ChangingProgressProvider>
                              </div>

                              <div class="pledged_dv">
                                <p>47.50%</p>
                                <span>Funded</span>
                              </div>

                              <div class="pledged_dv">
                                <p>$56,116</p>
                                <span>Pledged</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <div className="notFound-container">
                    <img
                      src={notFound}
                      alt="not found"
                      className="image-fluid"
                    />
                    <p className="mt-4">No Record Found</p>
                  </div>
                )}
              </>
            )}

            {/* <div class="col-md-4" onClick={handlePage}>
							<div class="dntn_prod">
								<div>
									<a href="#">
										<img src={CardImage2} class="img-fluid" alt="" />
									</a>
								</div>
								<div class="text-center">
									<h4>Service Center</h4>
								</div>
								<div class="dntn_prod_desc">
									<div style={{ width: "40px" }}>
										<ChangingProgressProvider values={[0, 20, 40, 60, 80, 100]}>
											{(percentage) => (
												<CircularProgressbar
													value={percentage}
													text={`${percentage}%`}
												/>
											)}
										</ChangingProgressProvider>
									</div>

									<div class="pledged_dv">
										<p>47.50%</p>
										<span>Funded</span>
									</div>

									<div class="pledged_dv">
										<p>$56,116</p>
										<span>Pledged</span>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-4" onClick={handlePage}>
							<div class="dntn_prod">
								<div>
									<a href="#">
										<img src={CardImage1} class="img-fluid" alt="" />
									</a>
								</div>
								<div class="text-center">
									<h4>Service Center</h4>
								</div>
								<div class="dntn_prod_desc">
									<div style={{ width: "40px" }}>
										<ChangingProgressProvider values={[0, 20, 40, 60, 80, 100]}>
											{(percentage) => (
												<CircularProgressbar
													value={percentage}
													text={`${percentage}%`}
												/>
											)}
										</ChangingProgressProvider>
									</div>

									<div class="pledged_dv">
										<p>47.50%</p>
										<span>Funded</span>
									</div>

									<div class="pledged_dv">
										<p>$56,116</p>
										<span>Pledged</span>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-4" onClick={handlePage}>
							<div class="dntn_prod">
								<div>
									<a href="#">
										<img src={CardImage1} class="img-fluid" alt="" />
									</a>
								</div>
								<div class="text-center">
									<h4>Service Center</h4>
								</div>
								<div class="dntn_prod_desc">
									<div style={{ width: "40px" }}>
										<ChangingProgressProvider values={[0, 20, 40, 60, 80, 100]}>
											{(percentage) => (
												<CircularProgressbar
													value={percentage}
													text={`${percentage}%`}
												/>
											)}
										</ChangingProgressProvider>
									</div>

									<div class="pledged_dv">
										<p>47.50%</p>
										<span>Funded</span>
									</div>

									<div class="pledged_dv">
										<p>$56,116</p>
										<span>Pledged</span>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-4" onClick={handlePage}>
							<div class="dntn_prod">
								<div>
									<a href="#">
										<img src={CardImage2} class="img-fluid" alt="" />
									</a>
								</div>
								<div class="text-center">
									<h4>Service Center</h4>
								</div>
								<div class="dntn_prod_desc">
									<div style={{ width: "40px" }}>
										<ChangingProgressProvider values={[0, 20, 40, 60, 80, 100]}>
											{(percentage) => (
												<CircularProgressbar
													value={percentage}
													text={`${percentage}%`}
												/>
											)}
										</ChangingProgressProvider>
									</div>

									<div class="pledged_dv">
										<p>47.50%</p>
										<span>Funded</span>
									</div>

									<div class="pledged_dv">
										<p>$56,116</p>
										<span>Pledged</span>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-4" onClick={handlePage}>
							<div class="dntn_prod">
								<div>
									<a href="#">
										<img src={CardImage1} class="img-fluid" alt="" />
									</a>
								</div>
								<div class="text-center">
									<h4>Service Center</h4>
								</div>
								<div class="dntn_prod_desc">
									<div style={{ width: "40px" }}>
										<ChangingProgressProvider values={[0, 20, 40, 60, 80, 100]}>
											{(percentage) => (
												<CircularProgressbar
													value={percentage}
													text={`${percentage}%`}
												/>
											)}
										</ChangingProgressProvider>
									</div>

									<div class="pledged_dv">
										<p className="mb-0">47.50%</p>
										<span>Funded</span>
									</div>

									<div class="pledged_dv">
										<p>$56,116</p>
										<span>Pledged</span>
									</div>
								</div>
							</div>
						</div> */}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default DonateListing;
