import React, { useState } from "react";
import Header from "../../Components/Header/Header";
import "../../assets/css/Login.css";
import { Link, useNavigate } from "react-router-dom";
import { arrow } from "../../constant";
import { toast } from "react-toastify";
import { signUp } from "../../redux/actions/AuthActions";
import { useDispatch } from "react-redux";

function Signup() {
	const dispatch = useDispatch()
	const Navigate = useNavigate()
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [btnLoading, setBtnLoading] = useState(false);

	const SignUpHandler = async (e) => {
		e.preventDefault();
		setBtnLoading(true);
		if (
		  !firstName ||
		  !lastName ||
		  !phone ||
		  !email ||
		  !password
		  // !confirmPassword ||
		) {
		  toast.error("Please Enter All Fields");
		  setBtnLoading(false);
		  return;
		}
		if (
		  !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
			email
		  )
		) {
		  toast.error("Invalid Email");
		  setBtnLoading(false);
		  return;
		}
	
		if (phone?.length < 10) {
		  toast.error("The phone no must be at least 10 characters");
		  setBtnLoading(false);
		  return;
		}
		if (password != confirmPassword) {
		  toast.error("Please enter same password and confirm password");
		  setBtnLoading(false);
		  return;
		} else {
		  var data = new FormData();
		  data.append("f_name", firstName);
		  data.append("l_name", lastName);
		  data.append("email", email);
		  data.append("phone", phone);
		  data.append("password", password);
		  data.append("confirm_password", confirmPassword);
		  console.log("complete Data", data);
		  let send = await dispatch(signUp(data, Navigate, setBtnLoading));
		}
	  };
	return (
		<>
			<Header />
			<section className="login">
				<div className="login-card">
					<div className="main-title-cont">
						<hr className="hr-line" />
						<h2 className="main-title">Sign Up</h2>
						<hr className="hr-line" />
					</div>
					<p className="inner-title">
						Welcome to <span>Hoho Farms</span>
					</p>
					<div className="regs-cont">
						<p className="regs-text">Already a Member ?</p>
						<p className="regs-text">
							<Link to="/login">Sign In</Link>
						</p>
					</div>
					<div className="form-cont row">
						<div className="col-lg-6 mb-3">
							<label>First Name</label>
							<input type="text" 
								className="input-custom" 
								value={firstName}
								onChange={(e)=>setFirstName(e.target.value)}
							/>
						</div>
						<div className="col-lg-6 mb-3">
							<label>Last Name</label>
							<input type="text" 
								className="input-custom" 
								value={lastName}
								onChange={(e)=>setLastName(e.target.value)}
							/>
						</div>
						<div className="col-lg-6 ">
							<label>Email Address</label>
							<input 
								type="text" 
								className="input-custom" 
								value={email}
								onChange={(e)=>setEmail(e.target.value)}
							/>
						</div>
						<div className="col-lg-6 ">
							<label>Contact Number</label>
							<input 
								type="number" 
								className="input-custom" 
								value={phone}
								onChange={(e)=>setPhone(e.target.value)}
							/>
						</div>
						<div className="col-lg-6 ">
							<label>Password</label>
							<input 
								type="password"
								className="input-custom" 
								value={password}
								onChange={(e)=>setPassword(e.target.value)}
							/>
						</div>
						<div className="col-lg-6 ">
							<label>Confirm Password</label>
							<input 
								type="password" 
								className="input-custom" 
								value={confirmPassword}
								onChange={(e)=>setConfirmPassword(e.target.value)}
							/>
						</div>

						<div className="btn-cont mt-4">
							<button className="btn loginBtn"
								   onClick={(e) => SignUpHandler(e)}
								   disabled={btnLoading}
							>
							{btnLoading ? "Loading..." : "SIGN UP"} <img src={arrow} alt="" />
							</button>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default Signup;
