import React from 'react'
import { notFound } from '../../constant'

const NoRecord = () => {
  return (
    <div className="notFound-container">
                    <img
                      src={notFound}
                      alt="not found"
                      className="image-fluid"
                    />
                    <p className="mt-4">No Reacord Found</p>
                  </div>
  )
}

export default NoRecord