import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../assets/css/Header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logoimg from "../../assets/img/logoimg.png";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import {
  faCartShopping,
  faUser,
  faMagnifyingGlass,
  faHeart,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { GetCategories, GetMembership, GetSiteSettingsApi } from "../../network/Network";
import { useDispatch, useSelector } from "react-redux";
import { AllCategoriesData, logout } from "../../redux/actions/AuthActions";

function Header() {
  const dispatch = useDispatch();
  const Token = useSelector((state) => state.AuthReducer.token);
  const CategoriesReduxData = useSelector(
    (state) => state.AuthReducer.categories
  );
  let currentUrl = window.location.href.split("/");
  const [mobView, setMobView] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchShow, setSearchShow] = useState(false);
  const [Show, setShow] = useState(false);
  const [mainSearch, setMainSearch] = useState("");

  // Get Pages Data
  useEffect(() => {
    GetSiteSettingsApi()
      .then((res) => {
        console.log(res);
        // dispatch(WebSiteSettings(res?.data?.response?.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const LogoutHandler = (e) => {
    e.preventDefault();
    setTimeout(async () => {
      let x = await dispatch(logout());
      window.location.href = "/login";
    }, 600);
  };

  const NavBarHandler = () => {
    setMobView(!mobView);
  };

  const HandleSearchShow = () => {
    setSearchShow(true);
  };
  const closeSearchShow = () => {
    setSearchShow(false);
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  // Get Categories Data
  useEffect(() => {
    GetCategories()
      .then((res) => {
        console.log(res);
        dispatch(AllCategoriesData(res?.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

   
  return (
    <>
      <header>
        <div className="secondaryHeader">
          <div className="container">
            <ul className="secondary-items"></ul>
          </div>
        </div>
        <div className="main-header">
          <div className="container">
            <div className="row align-items-center justify-content-between custom-width">
              <div className="col-md-2 col-sm-6 col-6">
                <div className="logoDv">
                  <Link to="/">
                    <img src={logoimg} className="img-fluid" alt="" />
                    {/* HOHO Farms */}
                  </Link>
                </div>
              </div>
              <div className="col-md-10 col-sm-6 col-6">
                <div className="navbarMenu">
                  <ul className="navItems ">
                    <li className="menu-items">
                      <Link
                        to="/"
                        className={`${
                          currentUrl?.[3] === "" ? "active" : null
                        }`}
                      >
                        HOME
                      </Link>
                    </li>
                    {/* <li className="menu-items"><Link to="/all-cars"  className={`${currentUrl?.[3] === "all-cars" ? "active" : null}`}>Find My Car</Link></li> */}

                    <li className="menu-items">
                      <Link
                        to="/all-plants"
                        className={`${
                          currentUrl?.[3] === "all-plants" ? "active" : null
                        }`}
                      >
                        ALL PLANTS
                      </Link>
                    </li>
                    <li className="menu-items">
                      <Link
                        to="/nutrients-additives"
                        className={`${
                          currentUrl?.[3] === "nutrients-additives"
                            ? "active"
                            : null
                        }`}
                      >
                        NUTRIENTS & ADDITIVES
                      </Link>
                    </li>
                    <li className="menu-items">
                      <Link
                        to="/planting-mixes"
                        className={`${
                          currentUrl?.[3] === "planting-mixes" ? "active" : null
                        }`}
                      >
                        PLANTING MIXES
                      </Link>
                    </li>
                    <li className="menu-items">
                      <Link
                        to="/products"
                        className={`${
                          currentUrl?.[3] === "products" ? "active" : null
                        }`}
                      >
                        PRODUCTS
                      </Link>
                    </li>

                    <li className="menu-items nav-item dropdown">
                      <Link
                        to="/booking-membership"
                        className={`${
                          currentUrl?.[3] === "all-cars"
                            ? "nav-link dropdown-toggle active"
                            : "nav-link dropdown-toggle"
                        }`}
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        BOOKING & MEMBERSHIP
                      </Link>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        {/* {CarCategoryDataRedux?.map((item, index) => {
                          return ( */}
                        <Link
                          to={`/garden-tour`}
                          // state={{ carStyle: item }}
                          className="dropdown-item header-drop header-drop-font"
                        >
                          GARDEN TOUR
                        </Link>
                        <Link
                          to={`/camping`}
                          // state={{ carStyle: item }}
                          className="dropdown-item header-drop header-drop-font"
                        >
                          CAMPING
                        </Link>
                        <Link
                          to={`/framers-booth`}
                          // state={{ carStyle: item }}
                          className="dropdown-item header-drop header-drop-font"
                        >
                          FARMERS BOOTH SPACE
                        </Link>
                        <Link
                          to={`/family-membership`}
                          // state={{ carStyle: item }}
                          className="dropdown-item header-drop header-drop-font"
                        >
                          Family Membership
                        </Link>
                        <Link
                          to={`/csa-membership`}
                          // state={{ carStyle: item }}
                          className="dropdown-item header-drop header-drop-font"
                        >
                          CSA MEMBERSHIP
                        </Link>
                        {/* );
                        })} */}
                      </div>
                    </li>
                    {/* <li className="menu-items nav-item dropdown">
											<Link
												to="/donate-listing"
												className={`${
													currentUrl?.[3] === "all-cars"
														? "nav-link dropdown-toggle active"
														: "nav-link dropdown-toggle"
												}`}
												id="navbarDropdown"
												role="button"
												data-toggle="dropdown"
												aria-haspopup="true"
												aria-expanded="false"
											>
												Donors
											</Link>
											<div
												className="dropdown-menu"
												aria-labelledby="navbarDropdown"
											>
												<Link
													to={`/donation`}
													// state={{ carStyle: item }}
													className="dropdown-item header-drop header-drop-font"
												>
													Donation
												</Link>
												<Link
													to={`/booking-details`}
													// state={{ carStyle: item }}
													className="dropdown-item header-drop header-drop-font"
												>
													Booking-Details
												</Link>
											</div>
										</li> */}

                    <li className="menu-items">
                      <Link
                        to="/donate-listing"
                        className={`${
                          currentUrl?.[3] === "donate-listing" ? "active" : null
                        }`}
                      >
                        DONATE
                      </Link>
                    </li>

                    <li className="menu-items">
                      <Link
                        to="/contact"
                        className={`${
                          currentUrl?.[3] === "contact" ? "active" : null
                        }`}
                      >
                        CONTACT US
                      </Link>
                    </li>
                  </ul>

                  <ul className="navItems ml-5">
                    <li className="menu-items">
                      <>
                        <Link
                          to="#"
                          className={`${
                            currentUrl?.[3] === "" ? "active" : null
                          }`}
                        >
                          {/* <FontAwesomeIcon icon={faMagnifyingGlass} /> */}

                          <SearchOutlinedIcon
                            className="set_icon"
                            onClick={HandleSearchShow}
                          />
                        </Link>
                      </>
                    </li>
                    <li className="menu-items">
                      <Link
                        to="/profile"
                        className={`${
                          currentUrl?.[3] === "profile" ? "active" : null
                        }`}
                      >
                        {/* <FontAwesomeIcon icon={faUser} /> */}

                        <PermIdentityIcon className="set_icon" />
                      </Link>
                    </li>
                    <li className="menu-items">
                      <Link
                        to="/cart"
                        className={`${
                          currentUrl?.[3] === "cart" ? "active" : null
                        }`}
                      >
                        {/* <FontAwesomeIcon icon={faCartShopping} /> */}
                        <ShoppingCartOutlinedIcon className="set_icon" />
                      </Link>
                    </li>
                    <li className="menu-items">
                      <Link
                        to="/wishlist"
                        className={`${
                          currentUrl?.[3] === "wishlist" ? "active" : null
                        }`}
                      >
                        {/* <FontAwesomeIcon icon={faHeart} /> */}
                        <FavoriteBorderIcon className="set_icon" />
                      </Link>
                    </li>
                    {Token ? (
                      <li className="menu-items">
                        <a
                          className=" btn-login"
                          onClick={(e) => LogoutHandler(e)}
                        >
                          Sign Out
                        </a>
                      </li>
                    ) : (
                      <li className="menu-items">
                        <Link to="/login" className=" btn-login ">
                          Sign In
                        </Link>
                      </li>
                    )}
                  </ul>
                  <div className="canvas-icon text-black heade-smoll">
                    <Link
                      to="#"
                      className={`${currentUrl?.[3] === "" ? "active" : null}`}
                    >
                      {/* <FontAwesomeIcon icon={faMagnifyingGlass} /> */}

                      <SearchOutlinedIcon
                        className="set_icon"
                        onClick={HandleSearchShow}
                      />
                    </Link>
                    <div onClick={() => NavBarHandler()}>
                      <i className="fa fa-bars" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- mobile header start --> */}
            <div
              className={`${mobView ? "mobile-header show" : "mobile-header"}`}
            >
              <div className="cancel" onClick={() => NavBarHandler()}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  className="bi bi-x"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </div>

              <ul className="mobile-nav">
                <li className="nav-item">
                  <Link
                    to="/"
                    className={`${currentUrl?.[3] === "" ? "active" : null}`}
                  >
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/all-plants"
                    className={`${
                      currentUrl?.[3] === "all-plants" ? "active" : null
                    }`}
                  >
                    All Plants
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/nutrients-additives"
                    className={`${
                      currentUrl?.[3] === "nutrients-additives"
                        ? "active"
                        : null
                    }`}
                  >
                    Nutrients Additives
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/planting-mixes"
                    className={`${
                      currentUrl?.[3] === "planting-mixes" ? "active" : null
                    }`}
                  >
                    Planting Mixes
                  </Link>
                </li>
                <li className="nav-item">
                  {/* <Link
                    to="/customize"
                    className={`${
                      currentUrl?.[3] === "customize" ? "active" : null
                    }`}
                  >
                    Customize
                  </Link> */}
                  <Link
                    to="/products"
                    className={`${
                      currentUrl?.[3] === "products" ? "active" : null
                    }`}
                  >
                    PRODUCTS
                  </Link>
                </li>
                <li className="menu-items nav-item dropdown">
                  <Link
                    to="/booking-membership"
                    className={`${
                      currentUrl?.[3] === "all-cars"
                        ? "nav-link dropdown-toggle active"
                        : "nav-link dropdown-toggle"
                    }`}
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    BOOKING & MEMBERSHIP
                  </Link>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    {/* {CarCategoryDataRedux?.map((item, index) => {
                          return ( */}
                    <Link
                      to={`/garden-tour`}
                      // state={{ carStyle: item }}
                      className="dropdown-item header-drop header-drop-font"
                    >
                      GARDEN TOUR
                    </Link>
                    <Link
                      to={`/camping`}
                      // state={{ carStyle: item }}
                      className="dropdown-item header-drop header-drop-font"
                    >
                      CAMPING
                    </Link>
                    <Link
                      to={`/framers-booth`}
                      // state={{ carStyle: item }}
                      className="dropdown-item header-drop header-drop-font"
                    >
                      FARMERS BOOTH SPACE
                    </Link>
                    <Link
                      to={`/csa-membership`}
                      // state={{ carStyle: item }}
                      className="dropdown-item header-drop header-drop-font"
                    >
                      CSA MEMBERSHIP
                    </Link>
                    {/* );
                        })} */}
                  </div>
                </li>
                <li className="nav-item">
                  <Link
                    to="/donate-listing"
                    className={`${
                      currentUrl?.[3] === "donate" ? "active" : null
                    }`}
                  >
                    Donate
                  </Link>
                </li>
                {/* <li className="nav-item"><Link to="/merchandise"  className={`${currentUrl?.[3] === "merchandise" ? "active" : null}`}>Merchandise</Link></li> */}
                <li className="nav-item">
                  <Link
                    to="/contact"
                    className={`${
                      currentUrl?.[3] === "contact" ? "active" : null
                    }`}
                  >
                    CONTACT US
                  </Link>
                </li>
                {/* <li className="nav-item">
                {Token ? (
                        <Link
                          to="/list-your-ads"
                          className={`${
                            currentUrl?.[3] === "list-your-ads" ? "active" : null
                          }`}
                        >
                          List Your Vehicle
                        </Link>
                      ) : (
                        <a
                          onClick={(e) => ListHandler(e)}
                          className={`${
                            currentUrl?.[3] === "list-your-ads" ? "active" : null
                          }`}
                        >
                          List Your Vehicle
                        </a>
                      )}
                </li> */}
              </ul>
              {/* <!-- secondary header items --> */}
              <ul className="secondary-items">
                {/* <li>
                  <Link
                    to="/download-game"
                    className="text-white downloads"
                    href="#"
                  >
                    Download Game
                  </Link>
                </li> */}
                <li className="flexBox-style navP-mob">
                  {/* {isLogin ? (
                    <>
                      <Link to="/profile">
                        <div className="header-profile">
                          {userData?.image == "def.png" ? (
                            <img
                              src={`${ramdomImage(
                                `${userData?.f_name}{""}${userData?.l_name}`
                              )}`}
                              className="image-fluid image-width"
                              style={{ borderRadius: "50%" }}
                              alt=""
                            />
                          ) : (
                            <img
                              src={`${ImageUrl?.customer_image_url}/${userData?.image}`}
                              className="image-fluid image-width"
                              style={{ height: "100%" }}
                              alt=""
                            />
                          )}
                        </div>
                      </Link>
                      <a
                        className="ml-2 log"
                        onClick={(e) => LogoutHandler(e)}
                        style={{ cursor: "pointer" }}
                      >
                        {loading ? "Loading.." : "Logout"}
                      </a>
                    </>
                  ) : (
                    <Link to="/login" className="log">
                      Login
                    </Link>
                  )} */}
                </li>
                <li className="mr-5">
                  <Link to="/terms-conditions">Terms</Link>
                </li>
                {/* <li>
                  {Token ? (
                    <Link to="/favourite">
                      <i className="fa fa-heart" aria-hidden="true"></i>
                    </Link>
                  ) : (
                    <a onClick={(e) => ListHandler(e)}>
                      <i className="fa fa-heart" aria-hidden="true"></i>
                    </a>
                  )}
                </li> */}
                <li className="cartItem">
                  <Link to="/cart">
                    <i className="fa fa-shopping-cart" aria-hidden="true">
                      <span className="chat-count">0</span>
                    </i>
                  </Link>
                </li>
                <li className="menu-items">
                  <Link
                    to="/wishlist"
                    className={`${
                      currentUrl?.[3] === "wishlist" ? "active" : null
                    }`}
                  >
                    {/* <FontAwesomeIcon icon={faHeart} /> */}
                    Wishlist
                  </Link>
                </li>
                <li className="menu-items">
                  <Link to="/login" className=" btn-login ">
                    Sign In
                  </Link>
                </li>
                <li className="comments">
                  {/* {Token ? (
                    <Link to="/chat">
                      <i className="fa fa-commenting-o" aria-hidden="true"></i>
                      <span className="chat-count">{CounterRedux}</span>
                    </Link>
                  ) : (
                    <a onClick={(e) => ListHandler(e)}>
                      <i className="fa fa-commenting-o" aria-hidden="true"></i>
                    </a>
                  )} */}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
      <div className={searchShow ? "search_box active" : "search_box"}>
        <div className="container">
          <div className="close_btn">
            <button className="close" onClick={closeSearchShow}>
              x
            </button>
          </div>
          <div className="form-group">
            <input
              className="form-control"
              value={mainSearch}
              placeholder="Search here..."
            />

            <>
              <button className="btn" type="submit">
                <i className="fa fa-search" aria-hidden="true"></i>
              </button>
            </>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
