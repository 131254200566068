import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import "../../assets/css/Blog.css";
import MainBanner from "../../Components/Banner/MainBanner";
import { arrow, blog1, notFound } from "../../constant";
import { useNavigate } from "react-router-dom";
import { GetBlogs } from "../../network/Network";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";

function Blogs() {
  const navigate = useNavigate();
  const ImageUrlRedux = useSelector((state) => state.AuthReducer.urlData);
  const [blogData, setBlogData] = useState([]);
  const [spinLoad, setSpinLoad] = useState(false);

  const blog = [
    {
      id: 1,
    },
    {
      id: 2,
    },
    {
      id: 3,
    },
    {
      id: 4,
    },
    {
      id: 5,
    },
    {
      id: 6,
    },
  ];

  useEffect(() => {
    setSpinLoad(true);
    GetBlogs()
      .then((res) => {
        console.log("blogs", res);
        setBlogData(res?.data);
        setSpinLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setSpinLoad(false);
      });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <MainBanner name={"Blogs"} />
      <section className="blogs-page">
        <div className="container">
          <div>
            <h2 className="main-title">Read Our Most recent Blogs</h2>
            <p className="para">
              {" "}
              Nisl vel pretium lectus quam id leo. Odio ut enim blandit volutpat
              maecenas volutpat blandit. Nunc vel risus commodo viverra
              maecenas. Lacus luctus accumsan tortor posuere ac ut consequat.{" "}
            </p>
          </div>

          <div className="row">
            {spinLoad ? (
              <>
                <div className="col-md-6">
                  <Skeleton count={5} />
                </div>
                <div className="col-md-6">
                  <Skeleton count={5} />
                </div>
              </>
            ) : (
              <>
                {blogData?.length > 0 ? (
                  <>
                    {blogData?.map((item, index) => {
                      return (
                        <div className="col-lg-6" key={index}>
                          <div
                            className="tour_card"
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate(`/blog-details/${item?.id}`)}
                          >
                            <div className="img-cont">
                              <img src={`${ImageUrlRedux?.blog_url}/${item?.image}`} alt="" className="image-fluid" />
                            </div>
                            <div>
                              <p className="tour_tag">{item?.tag} </p>
                              <p className="card_title">
								{item?.title}
                              </p>
                              <p className="card_para"
							  	dangerouslySetInnerHTML={{ __html: item?.content }}
							  />
                            </div>
                            <div>
                              <button className="btn blog_more">
                                Read More{" "}
                                <img
                                  src={arrow}
                                  className="image-fluid"
                                  alt=""
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <div className="notFound-container">
                    <img
                      src={notFound}
                      alt="not found"
                      className="image-fluid"
                    />
                    <p className="mt-4">No Reacord Found</p>
                  </div>
                )}
              </>
            )}
            {/* {blog?.map((item, index) => {
							return (
								<div className="col-lg-6" key={index}>
									<div
										className="tour_card"
										style={{ cursor: "pointer" }}
										onClick={() => navigate("/blog-details")}
									>
										<div className="img-cont">
											<img src={blog1} alt="" className="image-fluid" />
										</div>
										<div>
											<p className="tour_tag">Tourism </p>
											<p className="card_title">
												The Reason Why Everyone Love Farm
											</p>
											<p className="card_para">
												Lorem ipsum dolor sit amet, consectetur adipiscing elit,
												sed do eiusmod tempor incididunt ut labore et dolore
												magna aliqua. Ut enim ad minim veniam, quis nostrud
												exercitation ullamco laboris nisi ut aliquip ex ea
												commodo consequat.
											</p>
										</div>
										<div>
											<button className="btn blog_more">
												Read More{" "}
												<img src={arrow} className="image-fluid" alt="" />
											</button>
										</div>
									</div>
								</div>
							);
						})} */}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Blogs;
