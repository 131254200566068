import React, { useState } from "react";
import MainBanner from "../../Components/Banner/MainBanner";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import "../../assets/css/PlantingMixes.css";
import { m1 } from "../../constant";
import ProductCard from "../../Components/Cards/ProductCard";
import ReactPaginate from "react-paginate";
import NoRecord from "../../Components/NoRecord/NoRecord";
import { useEffect } from "react";
import { GetPlantsApi } from "../../network/Network";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";

function PlantingMixes() {
  const CategoriesReduxData = useSelector(
    (state) => state.AuthReducer.categories
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState();

  const handlePageClick = async (data) => {
    setCurrentPage(data?.selected + 1);
  };

  const [plantMixesData, setPlantMixesData] = useState([]);
  const [spinLoad, setSpinLoad] = useState(false);

  // Get ALL plant mixes
  useEffect(() => {
    setSpinLoad(true);
    let data = {
      name: "/planting-mixes",
    };
    GetPlantsApi(currentPage,data)
      .then((res) => {
        console.log(res);
        setPlantMixesData(res?.data?.data);
        const total = res?.data?.total;
        const limit = res?.data?.per_page;
        setPageCount(Math.ceil(total / limit));
        setSpinLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setSpinLoad(false);
      });
  }, [currentPage]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <MainBanner name={"Planting Mixes"} />
      <section className="plantingMixing-page">
        <div className="container">
        {CategoriesReduxData?.map((item, index) => {
            return (
              <>
                {item?.name == "PLANTING MIXES" ? (
                  <div className="heading" key={index}>
                  <h2 className="main-Title">{item?.subtitle}</h2>
                  <p className="main-para">
                    {item?.description}
                  </p>
                </div>
                ) : null}
              </>
            );
          })}

          <div className="row">
            {spinLoad ? (
               <>
               {[0, 1, 2, 3, 4, 5]?.map((item, index) => {
                 return (
                   <div className="col-md-4 mt-3" key={index}>
                     <Skeleton count={5} />
                   </div>
                 );
               })}
             </>
            ) : (
              <>
                {plantMixesData?.length > 0 ? (
                  <>
                    {plantMixesData?.map((item, index) => {
                      return (
                        <ProductCard 
							item={item} index={index} 
							colValue={"3"} 
							pageLink={"/planting-mixes-item"}
						/>
                      );
                    })}
                  </>
                ) : (
                  <NoRecord />
                )}
              </>
            )}
          </div>
          <div className="pagination-container mt-5">
            <ReactPaginate
              previousLabel="<"
              nextLabel=">"
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
             {plantMixesData?.length > 0 ? (
               <p className="Total-Product"> {plantMixesData?.length} PRODUCTS 1 - {plantMixesData?.length}</p>
                ) : null}
           
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default PlantingMixes;
