import React, { useState } from "react";
import Header from "../../Components/Header/Header";
import "../../assets/css/Login.css";
import { Link, useNavigate } from "react-router-dom";
import { arrow } from "../../constant";
import { toast } from "react-toastify";
import { forgotPassword } from "../../redux/actions/AuthActions";
import { useDispatch } from "react-redux";

function ForgotEmail() {
	const navigate = useNavigate();
	const dispatch = useDispatch()
	const [ email, setEmail ] = useState("")
	const [ loading, setLoading ] = useState("")
  
	const ForgetPasswordHandler = async (e) => {
		e.preventDefault();
		setLoading(true);
		if(!email){
		  toast.error("please enter Your email");
		  setLoading(false);
		}
		if (
		  !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
			email
		  )
		) {
		  toast.error("please enter correct email");
		  setLoading(false);
		  return;
		} else {
		  let data = { email: email,  platform: "hoho", };
		  setTimeout(async () => {
			setLoading(false);
			let send = await dispatch(forgotPassword(data));
			// Navigate("/forgot-password-Otp", { state: email });
			// Navigate("/newpassword");
			// window.location.href = "/newpassword";
		  }, 600);
		}
	  };

	return (
		<>
			<Header />
			<section className="login">
				<div className="login-card">
					<div className="main-title-cont">
						<h2 className="main-title">Forget Password</h2>
					</div>
					<p className="forget-title">Write Yoru email to Recover Account</p>

					<div className="form-cont">
						<div className="mb-3">
							<label>Your Email</label>
							<input type="text" className="input-custom" 
								value={email}
								onChange={(e)=>setEmail(e.target.value)}
							/>
						</div>

						<div className="btn-cont mt-2">
							<button className="btn loginBtn" 
								// onClick={() => navigate("/otp")}
								disabled={loading}
                         		onClick={(e)=>ForgetPasswordHandler(e)}
							>
								  {loading? "Loading.." : "SUBMIT"} <img src={arrow} alt="" />
							</button>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default ForgotEmail;
