import React, { useState } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import "../../assets/css/Profile.css";
import SideBar from "../../Components/SideBar/SideBar";
import { Link, useNavigate } from "react-router-dom";
import TopBar from "../../Components/SideBar/TopBar";
import NoRecord from "../../Components/NoRecord/NoRecord";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import { MyDonations } from "../../network/Network";

function MyDonation() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Token = useSelector((state) => state.AuthReducer.token);
  const userData = useSelector((state) => state.AuthReducer.users);
  const [myDonationData, setMyDonationData] = useState([]);
  const [spinLoad, setSpinLoad] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [selectedData, setSelectedData] = useState();
  const [isOpenViewDonationModal, setIsOpenViewDonationModal] = useState(false);

  const handlePageClick = async (data) => {
    setCurrentPage(data?.selected + 1);
  };

  useEffect(() => {
    setSpinLoad(true);
    MyDonations(currentPage, Token)
      .then((res) => {
        console.log("my donation", res);
        setMyDonationData(res?.data?.data?.data);
        const total = res?.data?.data?.total;
        const limit = res?.data?.data?.per_page;
        setPageCount(Math.ceil(total / limit));
        setSpinLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setSpinLoad(false);
      });
  }, [currentPage]);

  return (
    <>
      <Header />

      {/* <!-- Universal Banner Sec End Here --> */}
      <section className="profile">
        <div className="container">
          <div className="row profile-row">
            <SideBar
            />
            <div className="col-lg-9">
              <div className="tab-content" id="v-pills-tabContent">
                <div
                  className="tab-pane fade active show"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <TopBar />

                  <div className="row">
                    <div className="col-lg-12 ">
                      <h3 className="form-heading heading-top-cont">
                        My Donation
                      </h3>
                      {spinLoad ? (
                        <>
                          {[0, 1, 2, 3, 4, 5, 7, 8, 9]?.map((item, index) => {
                            return (
                              <div className="col-md-12 mt-3" key={index}>
                                <Skeleton count={5} />
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          {myDonationData?.length > 0 ? (
                            <div className="order-table-wrapper table-responsive">
                              <table>
                                <tbody>
                                  <tr className="row1">
                                    <th className="order-no">Campaign Name</th>
                                    <th className="order-date">
                                      Donation Date & Time
                                    </th>
                                    <th className="order-status">
                                      Payment Status
                                    </th>
                                    <th className="order-pay">Total</th>
                                    <th className="details">Details</th>
                                  </tr>
                                  {myDonationData?.map((item, index) => {
                                    return (
                                      <>
                                        <tr className="row2" key={index}>
                                          <td className="number">
                                            #{item?.campaign?.title}
                                          </td>
                                          <td className="date">
                                            {moment(item?.created_at).format(
                                              "MMMM Do YYYY, h:mm a"
                                            )}{" "}
                                          </td>
                                          <td className="paid confirm">
                                            {item?.payment_status}
                                            <i
                                              className="fa fa-check-circle"
                                              aria-hidden="true"
                                            ></i>
                                          </td>
                                          <td className="total-price">
                                            ${item?.amount}
                                          </td>

                                          <td className="order-details">
                                            <a
                                              className="order-details-btn"
                                              onClick={() =>
                                                navigate("/donation")
                                              }
                                            >
                                              view Details
                                            </a>
                                          </td>
                                        </tr>
                                      </>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            <NoRecord />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default MyDonation;
